import { DealItemSingle } from '../../../..';

export const determineFreeExtras = (dealItem: DealItemSingle): string[] => {
	return dealItem.output.extras
		.map((extra) => {
			const extraData = dealItem.output._data.extras.find(
				(extraData) => extraData._id === extra._id,
			);
			if (!extraData) {
				throw new Error('Invalid item, no extra data found');
			}

			return extra.items
				.map((item) => {
					const itemData = extraData.items.find((itemData) => itemData._id === item._id);
					if (!itemData) {
						throw new Error('Invalid item, no item data found');
					}

					if (!itemData.variant_pricing || !itemData.variants) {
						const isActuallyFree = itemData.price === 0;

						return {
							_id: item._id,
							weight: isActuallyFree ? 0 : item.quantity - (itemData.include ? 1 : 0),
							timestamp: item.timestamp,
						};
					}

					const variator = dealItem.output.variables.find(
						(variable) =>
							variable.variable === itemData.variants[0].variant_item.variable, // [TODO] taking a random variable is not programmatically correct
					);

					if (!variator) {
						throw new Error('Invalid item, no variator data found');
					}

					const variant = itemData.variants.find(
						(variant) => variant.variant_item._id === variator.attribute,
					);
					if (!variant) {
						throw new Error('Invalid item, no variant data found');
					}

					const isActuallyFree = variant.price === 0;

					return {
						_id: item._id,
						weight: isActuallyFree ? 0 : item.quantity - (itemData.include ? 1 : 0),
						timestamp: item.timestamp,
					};
				})
				.flat();
		})
		.flat()
		.filter((item) => item.weight)
		.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
		.map((item) => Array(item.weight).fill(item._id))
		.flat()
		.slice(0, dealItem.output._data.free_extras);
};
