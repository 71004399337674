import { apple } from './apple';
import { cash } from './cash';
import { coupon } from './coupon';
import { giftcard } from './giftcard';
import { initialState } from './initialState';
import { teya } from './teya';

export const payment = {
	initialState,
	apple,
	cash,
	coupon,
	giftcard,
	teya,
};
