import { useDeals } from './useDeals';

/**
 * SWR hook to fetch a deals by category slug
 * @param categorySlug string slug of the category
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useDealsByCategorySlug = (categorySlug: string) => {
	const swrProducts = useDeals();

	return {
		...swrProducts,
		data: swrProducts.data?.filter((item) => {
			return item.categories.find((category) => {
				if (category.category === null) {
					return false;
				} else {
					return category.category.slug.toLowerCase() === categorySlug;
				}
			});
		}),
	};
};
