import { Output, Product } from '..';

/**
 * Retrieves initial template values with included extras pre-selected.
 *
 * @param {Product['extras']} extras - The extras data from the product.
 * @returns {Output['extras']} The inferred extras.
 */
export const inferExtras = (extras: Product['extras']): Output['extras'] =>
	extras.map((igrd) => ({
		_id: igrd._id,
		items: igrd.items
			.filter((itm) => itm.include)
			.map((itm) => ({
				_id: itm._id,
				quantity: 1,
				timestamp: new Date().getTime(),
			})),
	}));
