import useSWR from 'swr';

import { AuthStatus, fetchers, tokenStorage } from '../..';

/**
 * SWR hook to fetch a products by ID
 * @returns returning data, isLoading, isValidating, mutate, and error
 * @returns returning isAuthenticated ('authenticated' or 'unauthenticated' or 'loading')
 */
export const useUser = () => {
	const accessToken = typeof localStorage !== 'undefined' ? tokenStorage.get().accessToken : '';
	const guestToken = typeof localStorage !== 'undefined' ? tokenStorage.get().guestToken : null;

	const swrResponse = useSWR(
		accessToken ? 'user' : undefined,
		async () => await fetchers.getUser(),
	);

	const { data, isLoading } = swrResponse;

	const authStatus: AuthStatus = (() => {
		if (data) {
			return 'authenticated';
		} else {
			if (isLoading) {
				return 'loading';
			} else {
				if (guestToken) {
					return 'guest';
				} else {
					return 'unauthenticated';
				}
			}
		}
	})();

	return { ...swrResponse, authStatus, guest: guestToken };
};
