import { Category, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Category[]} Array of Category data
 */
export const getCategories = (signal?: AbortSignal) => {
	const endpoint = `/categories`;

	return upsellAPI()
		.get<{
			data: Category[];
		}>(endpoint, { signal })
		.then((res) => res.data.data.sort((a, b) => a.position - b.position));
};
