import { Output, convertVariations } from '..';

/**
 * Validates the selection of variables for both single and split products.
 * @returns A boolean value indicating whether the extras selection is valid.
 */
export const validateVariablesSelection = (output: Output | null): boolean => {
	if (!output) {
		return false;
	}

	const isHavingVariations =
		output._data.type === 'variation' || output._data.type === 'variation_extras';

	if (!isHavingVariations) {
		return true;
	}

	if (isHavingVariations && !output._data.variations) {
		return false;
	}

	const isVariationValid = convertVariations.toVariation(
		output.variables,
		output._data.variations,
	);

	return Boolean(isVariationValid);
};
