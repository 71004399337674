import { mutate } from 'swr';

import { CartItem } from '../..';

export const cartStorageKey = `@upsell-kit/cart-items`;

/** Cart storage operations. */
export const cartStorage = {
	/**
	 * Retrieves cart items from storage.
	 *
	 * @returns {CartItem[]} The cart items.
	 */
	get: () => {
		if (typeof window !== 'undefined' && window?.upsellConfig?.platform === 'web') {
			return JSON.parse(localStorage.getItem(cartStorageKey) || '[]') as CartItem[];
		} else {
			// [TODO] app version
			return [];
		}
	},
	/**
	 * Sets updated cart items to storage.
	 *
	 * @param {CartItem[]} updated - The updated cart items.
	 */
	set: (updated: CartItem[]) => {
		if (typeof window !== 'undefined' && window?.upsellConfig?.platform === 'web') {
			localStorage.setItem(cartStorageKey, JSON.stringify(updated));
		} else {
			// [TODO] app version
		}
		mutate(cartStorageKey, updated);
	},

	/**
	 * Delete cart item in storage.
	 *
	 */
	delete: () => {
		if (typeof window !== 'undefined' && window?.upsellConfig?.platform === 'web') {
			localStorage.removeItem(cartStorageKey);
		} else {
			// [TODO] app version
		}
		mutate(cartStorageKey, []);
	},
};
