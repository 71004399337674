import { CartItem, Coupon, Output, convertVariations } from '..';

export const checkCouponItemEligibility = (item: CartItem, couponData: Coupon): boolean => {
	const couponType = couponData.coupon_type.type;

	if (couponType === 'order_total') {
		const { exclude_categories } = couponData.coupon_type.details;

		if (!exclude_categories.status) {
			return true;
		}

		const checker = (output: Output) =>
			Boolean(
				output._data.categories
					.map((category) => category.category._id)
					.find((categoryID) => !exclude_categories.values.includes(categoryID)),
			);

		return (
			(item.output !== null && checker(item.output)) ||
			(item.outputSplit !== null && checker(item.outputSplit))
		); // [TODO]:  what if it has different category in a split product
	} else if (couponType === 'product_category') {
		if (item.outputDeal) {
			return false;
		}

		const { target } = couponData.coupon_type.details;

		if (target.type === 'category') {
			const checker = (output: Output) =>
				Boolean(
					output._data.categories
						.map((category) => category.category._id)
						.find((categoryID) => target.categories.includes(categoryID)),
				);

			return (
				(item.output !== null && checker(item.output)) ||
				(item.outputSplit !== null && checker(item.outputSplit))
			);
		} else if (target.type === 'product') {
			const checker = (output: Output) => {
				return Boolean(
					target.products.find((product) => {
						const productID = output._data._id;
						const variantID = convertVariations.toVariation(
							output.variables,
							output._data.variations,
						)?._id;
						if (product.variants.length > 0) {
							return (
								product.product === productID &&
								variantID &&
								product.variants.includes(variantID)
							);
						} else {
							return product.product === productID;
						}
					}),
				);
			};

			return (
				(item.output !== null && checker(item.output)) ||
				(item.outputSplit !== null && checker(item.outputSplit))
			);
		} else {
			return false;
		}
	} else if (couponType === 'shipping') {
		return false;
	} else if (couponType === 'buyx_gety') {
		return false;
	} else {
		throw new Error('other coupon type is not supported yet');
	}
};
