import { mutate } from 'swr';

import { RegisterUserPayload, User, tokenStorage, upsellAPI } from '..';

/**
 * Register a new user
 * @param {RegisterUserPayload} payload - Payload of country code, phone number, name and email
 * @returns {Promise<{data: User, user_status: 'registered' | 'unregistered'}>} Response a user data and user status
 */
export const register = (payload: RegisterUserPayload) => {
	return upsellAPI()
		.post<{
			data: User;
			user_status: 'registered' | 'unregistered';
		}>(`/customers/register`, payload)
		.then((res) => {
			tokenStorage.save({
				accessToken: res.headers['x-token'],
				refreshToken: res.headers['x-refresh-token'],
			});

			mutate('user', res.data.data);

			return res.data;
		});
};
