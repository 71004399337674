import { preload } from 'swr';

import { fetchers } from '..';

/**
 * Preloads menu data for a specific deal.
 * @param dealID The ID of the deal for which menu data is being preloaded.
 * @param menuIDs An array of menu IDs for which data is being preloaded.
 * @returns A promise that resolves when all menu data is preloaded.
 */
export const preloadDealMenus = (dealID: string, menuIDs: string[]) => {
	const promises = menuIDs.map((menuID) => {
		return preload(`deal-items-${dealID}-${menuID}`, () =>
			fetchers.getMenuData({ dealID, menuID }),
		);
	});

	return Promise.all(promises);
};
