import { upsellAPI } from '../..';

/**
 * Generates OTP for user authentication
 * @param {string} phone - Phone string
 * @returns {Promise<{success: boolean}>} Response a status boolean
 * @returns {Promise<{success: boolean}>} Response a status boolean
 */
export const generate = (phone: string) =>
	upsellAPI()
		.post<{
			success: boolean;
		}>(`/auth/customers/upsell-otp/generate`, {
			phone,
		})
		.then((res) => res.data);
