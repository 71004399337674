import { Deal, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Deal} Deal data
 */
export const getDealByID = (dealID: string, signal?: AbortSignal) => {
	const endpoint = `/deals/${dealID}`;
	return upsellAPI()
		.get<{
			data: Deal;
		}>(endpoint, { signal })
		.then((res) => res.data.data);
};
