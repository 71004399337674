import { CartItemProductSingle, convertVariations } from '../../../..';

export const calculateVariation = (cartItem: CartItemProductSingle) => {
	if (cartItem.output._data.variations.length === 0) {
		return cartItem.output._data.base_price;
	} else {
		const variation = convertVariations.toVariation(
			cartItem.output.variables,
			cartItem.output._data.variations,
		);

		if (!variation) {
			throw new Error('Item invalid, price calculation failed, variation not found');
		}
		return variation.base_price;
	}
};
