import { Output, Product } from '..';

/**
 * Retrieves initial template values with included extras pre-selected.
 *
 * @param {Product['variables']} extras - The extras data from the product.
 * @returns {Output['variables']} The inferred extras.
 */
export const inferVariables = (
	variables: Product['variables'],
	preselect?: Output['variables'],
): Output['variables'] =>
	variables.map((variable) => ({
		variable: variable.variable._id,
		attribute:
			preselect?.find((preselect) => preselect.variable === variable.variable._id)
				?.attribute || '',
	}));
