// [TODO]: app version
import { GuestToken } from '../..';

const accessTokenKey = '@upsell-kit/access-token';
const refreshTokenKey = '@upsell-kit/refresh-token';
const guestTokenKey = '@upsell-kit/guest-token';

/**
 * Saves the access token to storage.
 *
 * @param {Object} param - The access and refresh tokens
 * @param {string} param.accessToken - The access token
 * @param {string} param.refreshToken - The refresh token
 */
const save = ({
	accessToken,
	refreshToken,
	guestToken,
}: {
	accessToken?: string;
	refreshToken?: string;
	guestToken?: GuestToken;
}) => {
	localStorage.setItem(accessTokenKey, JSON.stringify(accessToken || ''));
	localStorage.setItem(refreshTokenKey, JSON.stringify(refreshToken || ''));
	localStorage.setItem(guestTokenKey, JSON.stringify(guestToken || null));
};

/**
 * Retrieves the access token from storage.
 *
 * @returns {Promise<string | undefined>} The access token, if available.
 */
const get = (): {
	accessToken: string;
	refreshToken: string;
	guestToken: GuestToken | null;
} => {
	try {
		const accessToken = JSON.parse(localStorage.getItem(accessTokenKey) || '""');
		const refreshToken = JSON.parse(localStorage.getItem(refreshTokenKey) || '""');
		const guestToken = JSON.parse(localStorage.getItem(guestTokenKey) || 'null');

		return {
			accessToken,
			refreshToken,
			guestToken,
		};
	} catch (error) {
		return {
			accessToken: '',
			refreshToken: '',
			guestToken: null,
		};
	}
};

/**
 * Removes the access token from storage.
 */
const remove = () => {
	localStorage.removeItem(accessTokenKey);
	localStorage.removeItem(refreshTokenKey);
	localStorage.removeItem(guestTokenKey);
};

/**
 * Removes the access token from storage.
 */
const removeGuestToken = () => {
	localStorage.removeItem(accessTokenKey);
	localStorage.removeItem(refreshTokenKey);
	localStorage.removeItem(guestTokenKey);
};

export const tokenStorage = {
	save,
	get,
	remove,
	removeGuestToken,
};
