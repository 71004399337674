import {
	DealItem,
	DealItemSplit,
	Price,
	calculateAdditionalPrice,
	calculateProduct,
	combineSubPrice,
	convertVariations,
} from '../../../../..';

export const calculateDealItem = (dealItem: DealItem): Price => {
	if (dealItem.outputSplit === null) {
		const productPrice = calculateProduct(dealItem);

		if (dealItem.output._data.variations.length > 0) {
			const variation = convertVariations.toAvailableVariations(
				dealItem.output.variables,
				dealItem.output._data.available_variations,
			);

			if (!variation) {
				throw new Error('Invalid deal item, no variation found');
			}

			if (variation.use_product_price) {
				return productPrice;
			} else {
				const { type, value } = variation.additional_price;
				const base = calculateAdditionalPrice(type, value, productPrice.base);
				const extra = productPrice.extra;
				const price = [base, extra].reduce(
					(a, b) => ({
						final: Math.round(a.final + b.final),
						previous: Math.round(a.previous + b.previous),
					}),
					{
						final: 0,
						previous: 0,
					},
				);

				return {
					base,
					extra,
					price,
				};
			}
		} else {
			if (dealItem.output._data.use_product_price) {
				return productPrice;
			} else {
				const { type, value } = dealItem.output._data.additional_price;
				const base = calculateAdditionalPrice(type, value, productPrice.base);
				const extra = productPrice.extra;
				const price = [base, extra].reduce(
					(a, b) => ({
						final: Math.round(a.final + b.final),
						previous: Math.round(a.previous + b.previous),
					}),
					{
						final: 0,
						previous: 0,
					},
				);

				return {
					base,
					extra,
					price,
				};
			}
		}
	} else {
		const dealItemSplit = dealItem as DealItemSplit;
		const left = calculateDealItem({ ...dealItemSplit, outputSplit: null });
		const right = calculateDealItem({
			...dealItemSplit,
			output: dealItemSplit.outputSplit,
			outputSplit: null,
		});

		return {
			base: combineSubPrice(left.base, right.base),
			extra: combineSubPrice(left.extra, right.extra),
			price: combineSubPrice(left.price, right.price),
		};
	}
};
