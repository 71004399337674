import {
	CartItemProductSingle,
	DealItemSingle,
	calculateExtraItem,
	determineFreeExtras,
} from '../../../..';

export const calculateExtras = (cartItem: CartItemProductSingle | DealItemSingle) => {
	const freeExtraQty =
		'free_extras' in cartItem.output._data
			? determineFreeExtras(cartItem as DealItemSingle).length
			: 0;

	return cartItem.output.extras
		.map((extras) => {
			const extraData = cartItem.output._data.extras.find(
				(extraData) => extraData._id === extras._id,
			);

			if (!extraData) {
				throw new Error('Invalid item, no extra data found');
			}

			return extras.items
				.map((item) =>
					calculateExtraItem(item, extraData, cartItem.output.variables, freeExtraQty),
				)
				.reduce((a, b) => a + b, 0);
		})
		.reduce((a, b) => a + b, 0);
};
