import { isAfter, isBefore } from 'date-fns';

import { Branch, Deal } from '..';

/**
 * Checks if deals are available based on their active dates and branch availability.
 *
 * @param {Deal['active_dates'] | undefined} active_dates - The active dates of the deal.
 * @param {Object} [branch] - The branch information.
 * @param {Branch[]} branch.branches - The list of branches.
 * @param {Branch} branch.selectedBranch - The selected branch.
 * @returns {boolean} True if deals are available, false otherwise.
 */
export const isDealsAvailable = (
	active_dates: Deal['active_dates'] | undefined,
	branch?: {
		branches: Branch[];
		selectedBranch: Branch;
	},
) => {
	if (!active_dates) {
		return true;
	}

	if (branch) {
		if (branch.branches.length !== 8) {
			if (branch.selectedBranch) {
				if (branch.branches.length === 0) {
					return false;
				}

				if (
					!branch.branches.find((item: Branch) => item._id === branch.selectedBranch._id)
				) {
					return false;
				}
			} else {
				return false;
			}
		}
	}

	const currentDayInUTC = () =>
		['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][
			new Date().getUTCDay()
		];

	const icelandNow = {
		day: currentDayInUTC(),
	};

	switch (active_dates.type) {
		case 'periods':
			if (active_dates.details.periods.length <= 0) {
				return true;
			}

			if (active_dates.details.periods) {
				const timeNow = new Date().getTime();

				const daySchedule = active_dates.details.periods.find((item) => {
					const start = new Date(item.start_date).getTime();

					if (!item.start_date && !item.end_date) {
						return true;
					} // if no start and end date, set deal as active
					if (!item.end_date) {
						return timeNow >= start;
					} // if no end date, set deal as active if start date is passed

					const end = new Date(item.end_date).getTime();

					return timeNow >= start && timeNow <= end;
				});

				if (!daySchedule) {
					return false;
				}

				return true;
			}

			return true;
		case 'schedule':
			if (active_dates.details.active_dates_schedule.length <= 0) {
				return true;
			}

			if (active_dates.details.active_dates_schedule) {
				// let isOpen = false;
				const dayString = icelandNow.day;

				const daySchedule = active_dates.details.active_dates_schedule.find(
					(item) => item.active && item.day === dayString.toLowerCase(),
				);

				// if day not found, that means user didn't set active schedule for that day, so set it as inactive
				if (!daySchedule) {
					return false;
				}

				// if day found and full day is set, that means user set the deals as active for that day
				if (daySchedule.full_day) {
					return true;
				}

				// get current time and check if it's between start and end time
				const OpenTimes = daySchedule.times.find(({ time_start, time_end }) => {
					return (
						time_start !== null &&
						time_end !== null &&
						isAfter(
							new Date(),
							new Date(
								new Date().toISOString().split('T')[0] +
									'T' +
									time_start.split('T')[1],
							),
						) &&
						isBefore(
							new Date(),
							new Date(
								new Date().toISOString().split('T')[0] +
									'T' +
									time_end.split('T')[1],
							),
						)
					);
				});

				// if OpenTimes no found, that means deals is not active for that time, so set it as inactive
				if (!OpenTimes) {
					return false;
				}

				return true;
			}

			return true;
		default:
			return true;
	}
};
