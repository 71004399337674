import { mutate } from 'swr';

import { cartStorage, tokenStorage } from '..';

/**
 * Remove the token from the client side
 */
export const logout = async () => {
	tokenStorage.remove();
	cartStorage.delete();
	localStorage.removeItem('@upsell-kit/shipping');

	mutate('user', undefined);
};
