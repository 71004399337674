import type { Order, PaymentResult, PaymentState } from '../..';

export const initialValue: CheckoutContextType = {
	shipping: {
		method: '',
		branchID: '',
		data: {
			name: '',
			address: '',
			address_detail: '',
			postcode: '',
			shipping_note: '',
		},
	},
	payment: null,
	giftcards: [],
	additionalCost: [],
	note: '',
	coupon: '',
	paymentResult: null,
	changeShippingMethod: (_shippingMethod) => {
		return;
	},
	changeBranch: (_branchID) => {
		return;
	},
	updateShippingData: (_shippingData) => {
		return;
	},
	setCoupon: (_coupon) => {
		return;
	},
	setNote: (_coupon) => {
		return;
	},
	setPayment: (_payment) => {
		return;
	},
	applyGiftcard: (_giftcardCode) => {
		return;
	},
	removeGiftcard: (_giftcardCode) => {
		return;
	},
	checkout: () => {
		return;
	},
	resetCoupon: () => {
		return;
	},
	resetPayment: () => {
		return;
	},
	reset: () => {
		return;
	},
};

export type CheckoutContextType = {
	// STATE
	shipping: Shipping;
	payment: PaymentState | null;
	giftcards: string[];
	additionalCost: AdditionalCost[];
	note: string;
	coupon: string;
	paymentResult: PaymentResult | null;
	// ACTIONS
	changeShippingMethod(shippingMethod: Shipping['method']): void;
	changeBranch(branchID: string): void;
	updateShippingData(shippingData: Partial<Shipping['data']>): void;
	setPayment(payment: PaymentState | null): void;
	setCoupon(coupon: string): void;
	setNote(note: string): void;
	applyGiftcard(giftcardCode: string): void;
	removeGiftcard(giftcardCode: string): void;
	checkout: (params: {
		payment?: PaymentState;
		callback?: (paymentResult: PaymentResult, order: Order) => void;
	}) => void;
	resetCoupon: () => void;
	resetPayment: () => void;
	reset: () => void;
};

export type Shipping = {
	method: 'pickup' | 'delivery' | '';
	branchID: string;
	data: {
		name: string;
		address: string;
		address_detail: string;
		postcode: string;
		shipping_note: string;
	};
};

export type AdditionalCost = {
	name: string;
	amount: number;
	cost: number;
	id: string;
	quantity: number;
};

export type UseContext = () => CheckoutContextType;
