import { ReturnType } from '..';
import {
	CartItem,
	Category,
	Coupon,
	Price,
	calculateCartItem,
	checkCouponItemEligibility,
	joinText,
	toCurrency,
} from '../../../..';

type Params = {
	couponData: Coupon;
	items: CartItem[];
	categoriesData: Category[];
	cartPrice: Price;
	cartDiscount: number;
};

export const handleOrderTotal = ({
	couponData,
	items,
	categoriesData,
	cartPrice,
	cartDiscount,
}: Params): ReturnType => {
	const { exclude_categories, minimum_spend, max_value, amount, use_with_discount } =
		couponData.coupon_type.details;

	const affectedItems = items.filter((item) => checkCouponItemEligibility(item, couponData));

	const { affectedPriceWithoutDiscount, affectedPriceWithDiscount } = affectedItems
		.map((item) => calculateCartItem(item))
		.reduce(
			(a, b) => ({
				affectedPriceWithoutDiscount: a.affectedPriceWithoutDiscount + b.price.previous,
				affectedPriceWithDiscount: a.affectedPriceWithDiscount + b.price.final,
			}),
			{
				affectedPriceWithoutDiscount: 0,
				affectedPriceWithDiscount: 0,
			},
		);

	const affectedDiscount = affectedPriceWithoutDiscount - affectedPriceWithDiscount;

	const affectedPrice = use_with_discount
		? affectedPriceWithDiscount
		: affectedPriceWithoutDiscount;

	if (minimum_spend.status && affectedPrice < minimum_spend.value) {
		const unavailableCategories = exclude_categories.values.map((categoryID) => {
			const categoryData = categoriesData.find(
				(categoryData) => categoryData._id === categoryID,
			);
			return categoryData?.name || '';
		});

		return {
			discountAmount: cartDiscount,
			couponAmount: 0,
			afterDiscountAndCoupon: cartPrice.price.final,
			couponError: `
					Add ${toCurrency(minimum_spend.value - affectedPrice)} more 
					${
						unavailableCategories.length > 0
							? `on categories other than ${joinText.withOr(unavailableCategories)}`
							: ''
					} 
					to apply this coupon. 
					Minimum spending is ${toCurrency(minimum_spend.value)}
				`,
			couponSuccess: ``,
		};
	}

	if (amount.type === 'price') {
		const affectedCoupon = Math.min(amount.value, affectedPrice);

		const isDiscountPreferrable = affectedDiscount > affectedCoupon;

		const discountAmount = use_with_discount
			? cartDiscount
			: isDiscountPreferrable
				? cartDiscount
				: cartDiscount - affectedDiscount;

		const couponAmount = use_with_discount
			? affectedCoupon
			: isDiscountPreferrable
				? 0
				: affectedCoupon;

		if (isDiscountPreferrable && !use_with_discount) {
			return {
				discountAmount,
				couponAmount,
				afterDiscountAndCoupon: cartPrice.price.previous - (discountAmount + couponAmount),
				couponError: `
									Coupon is not applied because there is a bigger discount in place, 
									and it can not be used with coupon
								`,
				couponSuccess: ``,
			};
		} else {
			return {
				discountAmount,
				couponAmount,
				afterDiscountAndCoupon: cartPrice.price.previous - (discountAmount + couponAmount),
				couponError: '',
				couponSuccess:
					affectedDiscount && !use_with_discount
						? `
										${toCurrency(couponAmount)} OFF by Coupon is applied on select products, 
										but discounts on its product is removed
									`
						: `Coupon is applied, ${toCurrency(couponAmount)} OFF.`,
			};
		}
	} else {
		const amountValue = Math.round((affectedPrice * amount.value) / 100);
		if (max_value.status) {
			const affectedCoupon = Math.min(amountValue, max_value.value);

			const isDiscountPreferrable = affectedDiscount > affectedCoupon;

			const discountAmount = use_with_discount
				? cartDiscount
				: isDiscountPreferrable
					? cartDiscount
					: cartDiscount - affectedDiscount;

			const couponAmount = use_with_discount
				? affectedCoupon
				: isDiscountPreferrable
					? 0
					: affectedCoupon;

			if (isDiscountPreferrable && !use_with_discount) {
				return {
					discountAmount,
					couponAmount,
					afterDiscountAndCoupon:
						cartPrice.price.previous - (discountAmount + couponAmount),
					couponError: `
										Coupon is not applied because there is a bigger discount in place, 
										and it can not be used with coupon
									`,
					couponSuccess: ``,
				};
			} else {
				return {
					discountAmount,
					couponAmount,
					afterDiscountAndCoupon:
						cartPrice.price.previous - (discountAmount + couponAmount),
					couponError: '',
					couponSuccess:
						affectedDiscount && !use_with_discount
							? `
											${toCurrency(couponAmount)} OFF by Coupon is applied on select products, 
											but discounts on its product is removed
										`
							: `Coupon is applied, ${toCurrency(couponAmount)} OFF.`,
				};
			}
		} else {
			const affectedCoupon = amountValue;

			const isDiscountPreferrable = affectedDiscount > affectedCoupon;

			const discountAmount = use_with_discount
				? cartDiscount
				: isDiscountPreferrable
					? cartDiscount
					: cartDiscount - affectedDiscount;

			const couponAmount = use_with_discount
				? affectedCoupon
				: isDiscountPreferrable
					? 0
					: affectedCoupon;

			if (isDiscountPreferrable && !use_with_discount) {
				return {
					discountAmount,
					couponAmount,
					afterDiscountAndCoupon:
						cartPrice.price.previous - (discountAmount + couponAmount),
					couponError: `
										Coupon is not applied because there is a bigger discount in place, 
										and it can not be used with coupon
									`,
					couponSuccess: ``,
				};
			} else {
				return {
					discountAmount,
					couponAmount,
					afterDiscountAndCoupon:
						cartPrice.price.previous - (discountAmount + couponAmount),
					couponError: '',
					couponSuccess:
						affectedDiscount && !use_with_discount
							? `
											${toCurrency(couponAmount)} OFF by Coupon is applied on select products, 
											but discounts on its product is removed
										`
							: `Coupon is applied, ${toCurrency(couponAmount)} OFF.`,
				};
			}
		}
	}
};
