import useSWR from 'swr';

import { fetchers } from '../..';

interface Config {
	limit: string;
}

/**
 * SWR hook to fetch active orders
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useOrdersHistory = (config?: Config) =>
	useSWR('orders-history', () =>
		fetchers.getOrders({
			start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toUTCString(),
			end: new Date().toUTCString(),
			sort_by: 'order_number-desc',
			order_status: ['completed', 'cancelled'],
			...(config ? config : {}),
		}),
	);
