import {
	CartItem,
	CartItemDeal,
	CartItemProduct,
	calculateDeal,
	calculateProduct,
} from '../../../';

/* 
	This calculateCartItem functian handles both Deal and Product
		1. Deal
		2. Product
			- Single
			- Split
	and returns a price object containing the final price and previous price only if there is any dicount
*/

export type SubPrice = {
	final: number;
	previous: number; // [TODO]: rename to original
};

export type Price = {
	base: SubPrice;
	extra: SubPrice;
	price: SubPrice;
};

/**
 * Calculates the price for a cart item, handling both Deal and Product types.
 *
 * @param {Omit<CartItem, 'ref'>} cartItem - The cart item data.
 * @returns {Price} The calculated price object containing final and previous prices.
 */
export const calculateCartItem = (cartItem: Omit<CartItem, 'ref'> | undefined): Price => {
	if (cartItem?.outputDeal) {
		const cartItemDeal = cartItem as CartItemDeal;
		return calculateDeal(cartItemDeal);
	} else {
		const cartItemProduct = cartItem as CartItemProduct;
		return calculateProduct(cartItemProduct);
	}
};
