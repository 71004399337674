import { OrderDeal, OrderProductSingle, OutputDeal, fetchers } from '../../..';
import { constructDealItemOutput } from './constructDealItemOutput';

export const constructOutputDeal = async (orderItem: OrderDeal): Promise<OutputDeal> => {
	const dealData = await fetchers.getDealByID(orderItem.deals_id);

	return {
		_data: dealData, // [TODO]: need to revalidate the data snapshot
		items: await Promise.all(
			orderItem.menus.map(async (menu) => {
				if (menu.menu_items[0].type === 'split_product') {
					return {
						menuID: menu.menu_id,
						quantity: menu.menu_items[0].quantity,
						output: await constructDealItemOutput(
							menu.menu_items[0].split_items[0] as OrderProductSingle,
							orderItem.deals_id,
							menu.menu_id,
						),
						outputSplit: await constructDealItemOutput(
							menu.menu_items[0].split_items[1] as OrderProductSingle,
							orderItem.deals_id,
							menu.menu_id,
						),
					};
				} else {
					return {
						menuID: menu.menu_id,
						quantity: menu.menu_items[0].quantity,
						output: await constructDealItemOutput(
							menu.menu_items[0],
							orderItem.deals_id,
							menu.menu_id,
						),
						outputSplit: null,
					};
				}
			}),
		),
	};
};
