import { v4 as uuidv4 } from 'uuid';

import {
	CartItem,
	CartItemDeal,
	CartItemProductSingle,
	CartItemProductSplit,
	OrderItem,
	OrderProductSingle,
} from '../..';
import { constructOutput } from './toCart/constructOutput';
import { constructOutputDeal } from './toCart/constructOutputDeal';
import { convertDeal } from './toOrder/convertDeal';
import { convertProductSingle } from './toOrder/convertProductSingle';
import { convertProductSplit } from './toOrder/convertProductSplit';

export const convertCart = {
	/**
	 * Converts cart items to the format that backend recognizes.
	 *
	 * @param {CartItem[]} cartItems - The cart items.
	 * @returns {(OrderItemDeal | OrderItemProductSplit | OrderItemProductSingle)[]} The variation data.
	 */
	toOrder: (cartItems: CartItem[]) => {
		return cartItems.map((cartItem) => {
			if (cartItem.outputDeal !== null) {
				return convertDeal(cartItem as CartItemDeal);
			} else if (cartItem.outputSplit) {
				return convertProductSplit(cartItem as CartItemProductSplit);
			} else {
				return convertProductSingle(cartItem as CartItemProductSingle);
			}
		});
	},
	/**
	 * Converts data from order to the format that local cart system recognizes.
	 *
	 * [UNDER DEVELOPMENT]
	 */
	toCart: async (orderItems: OrderItem[]): Promise<CartItem[]> => {
		const result = await Promise.all(
			orderItems.map(async (orderItem) => {
				if ('deals_id' in orderItem) {
					return {
						output: null,
						outputSplit: null,
						outputDeal: await constructOutputDeal(orderItem),
						quantity: orderItem.quantity,
						ref: uuidv4().toString(),
					};
				} else if (orderItem.type === 'split_product') {
					return {
						output: await constructOutput(
							orderItem.split_items[0] as OrderProductSingle,
						),
						outputSplit: await constructOutput(
							orderItem.split_items[1] as OrderProductSingle,
						),
						outputDeal: null,
						quantity: orderItem.quantity,
						ref: uuidv4().toString(),
					};
				} else {
					return {
						output: await constructOutput(orderItem as OrderProductSingle),
						outputSplit: null,
						outputDeal: null,
						quantity: orderItem.quantity,
						ref: uuidv4().toString(),
					};
				}
			}),
		);

		return result;
	},
};
