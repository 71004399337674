import { errorLog } from '@monorepo/workers/logger';

import { ApplePaymentRequest, TeyaPaymentRequest, TeyaPaymentResult, upsellAPI } from '../..';

export const request = async (
	teyaPaymentRequest: TeyaPaymentRequest | ApplePaymentRequest,
): Promise<TeyaPaymentResult> => {
	return upsellAPI()
		.post<{
			data: TeyaPaymentResult;
		}>('/payments/teya-rpg/pay', teyaPaymentRequest)
		.then((response) => response.data.data)
		.catch((err) => {
			errorLog({ error: err.message });
			throw err;
		});
};
