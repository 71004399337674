import { Product, filterInvalidExtras } from '..';

export const isProductValid = (data: Product) => {
	const isProductInactive = (data: Product) => {
		return !data.product_availability.web_availability;
	};

	if (isProductInactive(data)) {
		throw new Error('Product is not active');
	}

	const noInvalidExtras = (data: Product) => ({
		...data,
		extras: filterInvalidExtras(data.extras, data),
	});

	return noInvalidExtras(data);
};
