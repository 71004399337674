'use client';

import { AppProps } from 'next/app';
import React, { createContext } from 'react';

import { initialValue } from './initialValue';
import { useContext } from './useContext';

export const Context = createContext(initialValue);

export const provide = (Page: AppProps['pageProps']) => (props: AppProps['pageProps']) => {
	const value = useContext();
	return React.createElement(Context.Provider, { value }, React.createElement(Page, props));
};

export const checkout = {
	provide,
	useContext: () => React.useContext(Context),
};
