import { DealItemOutput, OrderProductSingle, fetchers } from '../../..';

export const constructDealItemOutput = async (
	orderItem: OrderProductSingle,
	dealID: string,
	menuID: string,
): Promise<DealItemOutput> => {
	const menus = await fetchers.getMenuData({ dealID, menuID });

	const menuItem = menus.find((item) => item._id === orderItem.product_id);

	if (!menuItem) {
		throw new Error('Cache not found menu item');
	}

	return {
		variables:
			'variation' in orderItem
				? orderItem.variation.variants.map((variant) => ({
						variable: variant.variant,
						attribute: variant.attribute,
					}))
				: [],
		extras:
			'extras' in orderItem
				? orderItem.extras.map((extra) => ({
						_id: extra.extras_id,
						items: extra.items.map((item) => ({
							_id: item.extras_item_id,
							quantity: item.quantity,
							timestamp: 0, // [TODO]: refactor jadiin bukan timestamp
						})),
					}))
				: [],
		_data: menuItem, // [TODO]: need to revalidate the data snapshot
	};
};
