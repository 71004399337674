import { PaymentMethod, upsellAPI } from '../..';

/**
 * Function to fetch payment methods from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {PaymentMethod[]} Array of payment methods
 */
export const getPaymentMethods = (signal?: AbortSignal) => {
	const endpoint = `/payments`;

	return upsellAPI()
		.get<{
			data: PaymentMethod[];
		}>(endpoint, { signal })
		.then((res) => res.data.data)
		.then((methods) => {
			const target = window.upsellConfig.platform === 'app' ? 'mobile' : 'web';
			return methods.filter(
				(method) => method.availability.includes(target) && method.enabled,
			);
		});
};
