import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch a category by its ID
 * @param categoryID string ID of the category
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useCategoryByID = (categoryID: string) =>
	useSWR(`category-by-id-${categoryID}`, () => fetchers.getCategoryByID(categoryID));
