import upsell, { ApplePaymentRequest, ApplePaymentState, Order, TeyaPaymentResult } from '../..';

/**
 * Extracts card details and 3D Secure information from Apple payment data.
 * @param {ApplePaymentState} payment - The payment data from Apple Pay.
 * @returns {object} An object containing 3D Secure information and card details.
 */
const getCardAnd3DS = (payment: ApplePaymentState) => {
	const eciIndicator = parseInt(payment.paymentData?.eciIndicator || '');
	const onlinePaymentCryptogram = payment.paymentData?.onlinePaymentCryptogram || '';
	const SecurityLevelInd = eciIndicator === 1 || eciIndicator === 6 ? '1' : '2';

	return {
		three_d_secure: {
			DataType: 'manual',
			SecurityLevelInd: SecurityLevelInd,
			CAVV: onlinePaymentCryptogram,
			UCAF: onlinePaymentCryptogram,
			ThreeDSVersion: '1',
		},
		card: {
			card_number: payment.applicationPrimaryAccountNumber || '',
			exp_month: payment.applicationExpirationDate?.substring(2, 4) || '',
			exp_year: payment.applicationExpirationDate?.substring(0, 2) || '',
		},
	};
};

/**
 * Processes an Apple payment using Teya RPG payment gateway.
 *
 * This function extracts the necessary card and 3D secure information from the Apple payment state,
 * constructs a Teya payment request, and sends it to the Teya RPG payment gateway for processing.
 *
 * @param {ApplePaymentState} paymentData - The state of the Apple payment containing payment data.
 * @param {Order} order - The order associated with the payment.
 * @returns {Promise<TeyaPaymentResult>} - The result of the Teya payment processing.
 */
export const pay = async (
	paymentData: ApplePaymentState,
	order: Order,
): Promise<TeyaPaymentResult> => {
	const { card, three_d_secure } = getCardAnd3DS(paymentData);

	const teyaPaymentRequest: ApplePaymentRequest = {
		order_id: order._id,
		three_d_secure,
		card,
		thank_you_url: `${process.env.NEXT_PUBLIC_BASE_URL}/checkout/success?order=${order._id}`, // [TODO]: wrong redirection here
		verification_url: `${process.env.NEXT_PUBLIC_BASE_URL}/checkout/verification/${order._id}`, // [TODO]: wrong redirection here
	};

	const paymentResult = await upsell.payment.teya.request(teyaPaymentRequest);

	return {
		...paymentResult,
		payment_type: 'teya-rpg',
	};
};
