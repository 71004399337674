import { PaymentState } from '../@types';

/**
 * Validates the payment state input.
 * @returns A boolean value indicating whether the payment input is valid.
 */
export const validatePaymentInput = (payment: PaymentState): boolean => {
	if (payment.payment_method === 'cash') {
		return true;
	} else if (payment.payment_method === 'giftcard') {
		return true;
	} else if (payment.payment_method === 'teya-rpg') {
		if (!window.BAPIjs) {
			return false;
		}
		const isCardNumberValid = window.BAPIjs.isValidCardNumber(
			payment.card_number.replace(/\s/g, ''),
		);
		const isCardExpiryValid = window.BAPIjs.isValidExpDate(
			payment.card_expiry.split('/')[0],
			payment.card_expiry.split('/')[1],
		);
		const isCardCVCValid = window.BAPIjs.isValidCVC(payment.card_cvc);

		return isCardNumberValid && isCardExpiryValid && isCardCVCValid;
	} else if (payment.payment_method === 'apple-pay') {
		return true;
	} else {
		return false;
	}
};
