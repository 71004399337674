export const constructParams = (
	url: string,
	params: Record<string, string | string[] | undefined>,
) => {
	if (Object.keys(params).length === 0) {
		return url;
	}

	return Object.keys(params).reduce(
		(previous, current, i) => {
			const value = Array.isArray(params[current])
				? (params[current] as string[]).join(',')
				: params[current];

			if (typeof value === 'string') {
				if (i === 0) {
					return previous + current + '=' + value;
				} else {
					return previous + '&' + current + '=' + value;
				}
			} else {
				return previous;
			}
		},
		url + (url.includes('?') ? '&' : '?'),
	);
};
