import { useState } from 'react';
import { cache } from 'swr/_internal';

import upsell, {
	CartItem,
	CartItemDeal,
	CartItemProduct,
	Deal,
	DealItem,
	DealItemOutput,
	Output,
	Price,
	Product,
	ProductExtended,
	calculateDeal,
	calculateDealItem,
	calculateProduct,
	cartStorage,
	convertVariations,
	getVariationsData,
	inferExtras,
	inferVariables,
	preloadDealMenus,
	preselectVariables,
	preselectVariablesPartially,
	validateVariablesSelection,
} from '../..';
import {
	CustomizationContextType,
	CustomizationParams,
	UseContext,
	initialValue,
} from './initialValue';

export const useContext: UseContext = () => {
	// Main state
	const [quantity, setQuantity] = useState(initialValue.quantity);
	const [output, setOutput] = useState(initialValue.output);
	const [outputSplit, setOutputSplit] = useState(initialValue.outputSplit);
	const [outputDeal, setOutputDeal] = useState(initialValue.outputDeal);

	// Relative state based on sideFocus
	const [sideFocus, setSideFocus] = useState<'left' | 'right'>('left');
	const activeOutput = sideFocus === 'left' ? output : outputSplit;
	const activeSetOutput = sideFocus === 'left' ? setOutput : setOutputSplit;
	const inactiveOutput = sideFocus === 'left' ? outputSplit : output;
	const inactiveSetOutput = sideFocus === 'left' ? setOutputSplit : setOutput;

	// Construct the CartItem
	const cartItem: Omit<CartItem, 'ref'> = {
		quantity,
		output,
		outputSplit,
		outputDeal,
	};

	// Initializes the customization context with the provided data and parameters.
	const init: CustomizationContextType['init'] = (data, params) => {
		setSideFocus(initialValue.sideFocus);

		if (params?.edit) {
			// Editing session, no need to wait for data fetching
			const cartItem = cartStorage.get().find((item) => item.ref === params.edit);
			if (!cartItem) {
				return;
			} // need to wait for cart data retrieval

			if (cartItem?.outputDeal) {
				// Deal goes here

				setOutputDeal(cartItem.outputDeal);
				setQuantity(cartItem.quantity);
				preloadDealMenus(
					cartItem.outputDeal._data._id,
					cartItem.outputDeal._data.menus.map((menu) => menu._id),
				);
			} else {
				// Product goes here

				setOutput(cartItem.output);
				setOutputSplit(cartItem.outputSplit);
				setQuantity(cartItem.quantity);
			}
		} else if (data) {
			// New session, using new data
			if ('deals_type' in data) {
				// Deal goes here

				setOutputDeal({
					_data: data,
					items: [],
				});
				preloadDealMenus(
					data._id,
					data.menus.map((menu) => menu._id),
				);
			} else {
				// Product goes here

				if (!params?.menuID) {
					// Independent product session
					applyProduct(data, params);
				} else {
					// Deal's item session
					if (!outputDeal) {
						throw new Error('Invalid action, no deal session is found');
					}

					const dealItem = outputDeal.items.find(
						(item) =>
							item.menuID === params?.menuID && item.output._data._id === data._id,
					);

					if (dealItem) {
						// Deal's item session, but editing

						setOutput(dealItem.output);
						setOutputSplit(dealItem.outputSplit);
						setQuantity(dealItem.quantity);
					} else {
						// getting cache from swr, supposedly should have been provided
						const menuItem = (
							cache.get(`deal-items-${outputDeal._data._id}-${params.menuID}`)
								?.data as ProductExtended[]
						)?.find((item) => item._id === data._id);

						if (!menuItem) {
							throw new Error('Deal item data not found, cannot initialize page');
						}

						applyProduct(menuItem, params);
						setQuantity(menuItem.quantity);
					}
				}
			}
		}
	};

	// Applies the selected product or deal data to the customization context.
	const applyProduct = (product: Product | ProductExtended, params?: CustomizationParams) => {
		const variationsData = getVariationsData(product, inactiveOutput?._data);

		const variables = (() => {
			const currentVariables = inactiveOutput?.variables || activeOutput?.variables;
			const currentVariation = convertVariations.toVariation(
				inactiveOutput?.variables || activeOutput?.variables || [],
				inactiveOutput?._data.variations || activeOutput?._data.variations || [],
			);

			if (validateVariablesSelection(activeOutput)) {
				return preselectVariables(variationsData, currentVariation);
			} else {
				const validatePreselection = (variables: Output['variables']) => {
					return variables.filter((variable) => {
						const isAlwaysInactive = !variationsData
							.filter((variation) => variation.active)
							.find((variation) =>
								variation.variants.find(
									(variant) =>
										variant.variable._id === variable.variable &&
										variant.attribute._id === variable.attribute,
								),
							);
						return !isAlwaysInactive;
					});
				};

				const preselect = params?.preselect || false;

				if (preselect === 'auto') {
					return preselectVariables(variationsData, currentVariation);
				} else if (preselect === 'partial') {
					return preselectVariablesPartially(
						variationsData,
						product.variables,
						inferVariables(
							product.variables,
							currentVariables ? validatePreselection(currentVariables) : undefined,
						),
					);
				} else if (Array.isArray(preselect)) {
					return inferVariables(
						product.variables,
						currentVariables
							? validatePreselection(currentVariables)
							: validatePreselection(preselect),
					);
				} else {
					return inferVariables(
						product.variables,
						currentVariables ? validatePreselection(currentVariables) : undefined,
					);
				}
			}
		})();

		activeSetOutput({
			_data: product,
			variables,
			extras: inferExtras(product.extras),
		});

		if (inactiveOutput) {
			inactiveSetOutput({
				...inactiveOutput,
				variables,
			});
		}
	};

	// Splits the customization view for a dual pizza order.
	const split: CustomizationContextType['split'] = () => {
		setOutputSplit(output);
		setSideFocus('right');
	};

	// Un-splits the customization view to return to single pizza mode.
	const unsplit: CustomizationContextType['unsplit'] = () => {
		setOutput(output);
		setOutputSplit(initialValue.outputSplit);
		setSideFocus('left');
	};

	// Increases the quantity of the selected product or deal.
	const increaseQuantity: CustomizationContextType['increaseQuantity'] = () => {
		setQuantity(quantity + 1);
	};

	// Decreases the quantity of the selected product or deal, ensuring it remains at least 1.
	const decreaseQuantity: CustomizationContextType['decreaseQuantity'] = () => {
		if (quantity > 1) {
			setQuantity(quantity - 1);
		}
	};

	// Updates the selected variables of the product or deal.
	const updateVariable: CustomizationContextType['updateVariable'] = (
		variableID,
		attributeID,
	) => {
		if (!activeOutput) {
			return;
		}

		const newVariables = activeOutput.variables.map((variable) =>
			variable.variable === variableID
				? {
						variable: variableID,
						attribute: attributeID,
					}
				: variable,
		);

		const variationsData = getVariationsData(activeOutput._data, inactiveOutput?._data);

		const newVariation = convertVariations.toVariation(newVariables, variationsData);

		if (!newVariation || newVariation.active) {
			/* In single pizza, update is done to left side only */
			activeSetOutput({
				...activeOutput,
				variables: newVariables,
			});

			/* In split pizza, update is done to both side  */
			if (inactiveOutput) {
				inactiveSetOutput({
					...inactiveOutput,
					variables: newVariables,
				});
			}
		} else {
			const substituteVariation = variationsData
				.filter((variation) =>
					variation.variants.find(
						(variant) =>
							variant.variable._id === variableID &&
							variant.attribute._id === attributeID,
					),
				)
				.find((variation) => variation.active);

			if (substituteVariation) {
				const substituteVariables = convertVariations.toVariables(substituteVariation);

				/* In single pizza, update is done to left side only */
				activeSetOutput({
					...activeOutput,
					variables: substituteVariables,
				});

				/* In split pizza, update is done to both side  */
				if (inactiveOutput) {
					inactiveSetOutput({
						...inactiveOutput,
						variables: substituteVariables,
					});
				}
			}
		}
	};

	// Updates the selected extra (e.g., topping) of the product or deal.
	const updateExtra: CustomizationContextType['updateExtra'] = (extraID, itemID, quantity) => {
		if (!activeOutput) {
			return;
		}

		const operations = {
			add: () =>
				activeOutput.extras.map((extra) => {
					if (extra._id === extraID) {
						return {
							...extra,
							items: [
								...extra.items,
								{ _id: itemID, quantity, timestamp: new Date().getTime() },
							],
						};
					} else {
						return extra;
					}
				}),
			remove: () =>
				activeOutput.extras.map((extra) => {
					if (extra._id === extraID) {
						return {
							...extra,
							items: extra.items.filter((item) => item._id !== itemID),
						};
					} else {
						return extra;
					}
				}),
			update: () =>
				activeOutput.extras.map((extra) => {
					if (extra._id === extraID) {
						return {
							...extra,
							items: extra.items.map((item) => {
								if (item._id === itemID) {
									return {
										...item,
										quantity: quantity,
										timestamp: new Date().getTime(),
									};
								} else {
									return item;
								}
							}),
						};
					} else {
						return extra;
					}
				}),
		};

		const updatedExtras = (() => {
			const isSelected = Boolean(
				activeOutput.extras
					.find((extra) => extra._id === extraID)
					?.items.find((item) => item._id === itemID),
			);

			if (!isSelected) {
				return operations.add();
			} else if (quantity === 0) {
				return operations.remove();
			} else {
				return operations.update();
			}
		})();

		activeSetOutput({
			...activeOutput,
			extras: updatedExtras,
		});
	};

	// Calculates the total price for the selected deal.
	const getDealPrice: CustomizationContextType['getDealPrice'] = (): Price => {
		if (!outputDeal) {
			return initialValue.getDealPrice();
		}
		return calculateDeal(cartItem as CartItemDeal);
	};

	// Calculates the total price for the selected deal item.
	const getDealItemPrice: CustomizationContextType['getDealItemPrice'] = (): Price => {
		if (!output || !validateVariablesSelection(activeOutput)) {
			return initialValue.getProductPrice();
		}
		return calculateDealItem({
			menuID: '',
			output,
			outputSplit,
			quantity,
		} as DealItem);
	};

	// Calculates the total price for the selected product.
	const getProductPrice: CustomizationContextType['getProductPrice'] = (): Price => {
		if (!output || !validateVariablesSelection(activeOutput)) {
			return initialValue.getProductPrice();
		}
		return calculateProduct(cartItem as CartItemProduct);
	};

	// Adds a deal item to the current deal session.
	const addDealItem: CustomizationContextType['addDealItem'] = (dealItem: DealItem) => {
		if (!outputDeal) {
			throw new Error('Invalid action, no deal session is found');
		}

		setOutputDeal({
			...outputDeal,
			items: [
				...outputDeal.items.filter((item) => item.menuID !== dealItem.menuID),
				dealItem,
			].sort(
				(a, b) =>
					outputDeal._data.menus.findIndex((menu) => menu._id === a.menuID) -
					outputDeal._data.menus.findIndex((menu) => menu._id === b.menuID),
			),
		});
	};

	const applyDealsWithPreselectedItem = (
		deal: Deal,
		menuID: string,
		product: ProductExtended,
		callback: (status: string) => void,
	) => {
		if (!activeOutput || !product || !product.available_variations) {
			callback('invalid_data');

			return;
		}

		const { variables, extras } = activeOutput;

		if (!variables) {
			callback('variation_not_selected');

			return;
		}

		const isVariationAvailable = convertVariations.toAvailableVariations(
			variables,
			product.available_variations,
		);

		if (!isVariationAvailable) {
			callback('variation_not_available');

			setOutputDeal({
				_data: deal,
				items: [],
			});
			return;
		}

		const dealData = {
			_data: deal,
			items: [
				{
					menuID: menuID,
					output: {
						_data: product,
						variables,
						extras,
					},
					outputSplit: null,
					quantity: 1,
				},
			],
		};

		setOutputDeal(dealData);

		callback('success');
	};

	// Submits the current customization, either adding to cart or updating an existing item.
	const submit: CustomizationContextType['submit'] = (
		params?: CustomizationParams,
		callback?: () => void,
	) => {
		if (params?.menuID) {
			addDealItem({
				menuID: params.menuID,
				quantity: quantity,
				output: output as DealItemOutput,
				outputSplit: outputSplit as DealItemOutput,
			});

			setOutput(initialValue.output);
			setOutputSplit(initialValue.outputSplit);
		} else if (params?.edit) {
			upsell.cart.actions.update(params.edit, cartItem);
			reset();
		} else {
			upsell.cart.actions.add(cartItem);
			reset();
		}

		if (callback) {
			callback();
		}
	};

	// Resets the customization context to its initial state.
	const reset: CustomizationContextType['reset'] = () => {
		setOutput(initialValue.output);
		setOutputSplit(initialValue.outputSplit);
		setOutputDeal(initialValue.outputDeal);
		setQuantity(initialValue.quantity);

		setSideFocus(initialValue.sideFocus);
	};

	return {
		// STATE
		quantity,
		output,
		outputSplit,
		outputDeal,
		sideFocus,
		activeOutput,
		// ACTIONS
		init,
		split,
		unsplit,
		setSideFocus,
		increaseQuantity,
		decreaseQuantity,
		updateVariable,
		updateExtra,
		getDealPrice,
		getDealItemPrice,
		getProductPrice,
		addDealItem,
		submit,
		applyDealsWithPreselectedItem,
		reset,
	};
};
