import { Product, ProductExtended } from '..';

/**
 * Retrieves an intersection from two sets of data for the available variables in split pizza.
 *
 * @param {Product} leftData - The data for the left side of the split pizza.
 * @param {Product} rightData - The data for the right side of the split pizza.
 * @returns {Variable[]} The combined and labeled variables data.
 */
export const getVariationsData = (
	leftData: Product | ProductExtended,
	rightData?: Product | ProductExtended,
): Product['variations'] => {
	if (!rightData) {
		return filterAvailable(leftData);
	} else {
		return filterAvailable(leftData).map((leftVariation) => {
			const rightVariation = filterAvailable(rightData).find(
				(rightVariation) =>
					leftVariation.variants.length === rightVariation.variants.length &&
					rightVariation.variants.every((rightVariant) => {
						const leftVariant = leftVariation.variants.find(
							(leftVariant) => leftVariant.variable._id === rightVariant.variable._id,
						);
						return leftVariant?.attribute._id === rightVariant.attribute._id;
					}),
			);

			return {
				...leftVariation,
				variants: leftVariation.variants,
				active: Boolean(leftVariation.active && rightVariation?.active),
				is_default_variation: Boolean(
					leftVariation.is_default_variation && rightVariation?.is_default_variation,
				),
			};
		});
	}
};

const filterAvailable = (data: Product | ProductExtended): Product['variations'] => {
	if ('available_variations' in data) {
		return data.variations.map((variation) => {
			const availableVariation = data.available_variations.find((availableVariation) =>
				availableVariation.variants.every(
					(availableVariant) =>
						availableVariant.attribute ===
						variation.variants.find(
							(variant) => variant.variable._id === availableVariant.variant,
						)?.attribute._id,
				),
			);

			return {
				...variation,
				active: Boolean(availableVariation),
			};
		});
	} else {
		return data.variations;
	}
};
