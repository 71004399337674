import useSWR from 'swr';

import { fetchers, isDeepEqual } from '../..';

/**
 * SWR hook to fetch a deal by ID
 * @param dealID string ID of the deal
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useDealByID = (dealID: string) =>
	useSWR(!dealID ? null : `deal-by-id-${dealID}`, () => fetchers.getDealByID(dealID), {
		compare: (a, b) => {
			return isDeepEqual(
				{
					...a,
					branches: null,
					menus: a?.menus.map((menu) => ({
						...menu,
						menu_items: menu.menu_items.map((menu_item) => ({
							...menu_item,
							product: null,
							category: null,
						})),
					})),
				},
				{
					...b,
					branches: null,
					menus: b?.menus.map((menu) => ({
						...menu,
						menu_items: menu.menu_items.map((menu_item) => ({
							...menu_item,
							product: null,
							category: null,
						})),
					})),
				},
			);
		},
	});
