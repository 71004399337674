import { CartItem } from '..';

/**
 * Validates the selection of items in a deal based on required menus.
 *
 * @param {CartItem['outputDeal']} outputDeal - The deal item to validate.
 * @returns {boolean} Returns true if all required menus are selected in the deal.
 */
export const validateDealSelection = (outputDeal: CartItem['outputDeal']) =>
	outputDeal?._data.menus
		.filter((menu) => menu.required)
		.every((menu) => outputDeal.items.find((item) => item.menuID === menu._id));
