import { mutate } from 'swr';

import { tokenStorage } from '../..';

/**
 * End a guest mode session
 *
 */
export const end = () => {
	tokenStorage.removeGuestToken();
	mutate('user');
};
