import { Output, Product, ProductExtended, getVariationsData } from '..';

/**
 * Filters products that are possible split partners based on certain criteria.
 *
 * @param products An array of products to filter.
 * @param activeOutput The active output for which split partners need to be filtered.
 * @returns An array of products that are potential split partners.
 */
export const filterPossibleSplitPartner = (
	products: Product[] | ProductExtended[],
	activeOutput: Output,
): Product[] | ProductExtended[] =>
	products.filter((product) => {
		const isCategoryMatch = activeOutput._data.categories
			.map((category) => category.category._id)
			.some((categoryID) =>
				product.categories.map((category) => category.category._id).includes(categoryID),
			);

		const isTypeMatch = activeOutput._data.type === product.type;

		const isSplittable = product.split_item;

		const isVariationMatch = getVariationsData(activeOutput._data, product).find(
			(variation) => variation.active,
		);

		return isCategoryMatch && isTypeMatch && isSplittable && isVariationMatch;
	});
