import { MenuItemExtended, removeUnselectable, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 *
 * @param dealID string ID of the deal
 * @param menuID string ID of the menu
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Deal[]} Array of Deal data
 */
export const getMenuData = async (
	{ dealID, menuID }: { dealID: string; menuID: string },
	signal?: AbortSignal,
) => {
	return upsellAPI()
		.get<{ data: MenuItemExtended[] }>(
			'/deals/' + dealID + '/menus/' + menuID + '/menu_items',
			{ signal },
		)
		.then((res) => prepare(res.data.data, menuID));
};

const prepare = (data: MenuItemExtended[], menuID: string) => {
	const checkLayer = (items: MenuItemExtended[]): MenuItemExtended[] => {
		const securityCheck = (childItems: MenuItemExtended['items']) =>
			childItems
				.filter((item) => item.product_availability.web_availability)
				.map((item) => ({
					...item,
					categories: item.categories.filter((c) => c.category !== null),
				}));
		const ress = items.map((child) => ({
			...child,
			items: securityCheck(child.items),
		}));

		return ress;
	};

	const items = checkLayer(data)
		.map((child) =>
			child.items.map((item) => ({
				...item,
				quantity: child.quantity,
				free_extras: child.free_extras,
				menu_id: menuID,
				use_product_price: child.use_product_price,
				additional_price: child.additional_price,
				available_variations: child.available_variations,
			})),
		)
		.flat();

	const final = removeUnselectable(items);

	return final;
};
