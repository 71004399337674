import upsell, { Product, ProductExtended, convertVariations, getVariationsData } from '../..';

/**
 * Retrieves an variable data from one or two sets of data for the available variables.
 *
 * @returns {Product['variables'] | ProductExtended['variables']} The combined and labeled variables data.
 */
export const useVariablesState = (): Product['variables'] | ProductExtended['variables'] => {
	const { activeOutput, output, outputSplit, updateVariable } =
		upsell.provider.customization.useContext();

	if (!output) {
		return [];
	}

	const leftData = output._data;
	const rightData = outputSplit?._data;

	if (!activeOutput) {
		return [];
	}

	const combineVariables = (leftData: Product, rightData: Product) =>
		leftData.variables
			.map((leftVariable) => {
				const rightVariable = rightData.variables.find(
					(rightVariable) => rightVariable.variable._id === leftVariable.variable._id,
				);

				if (rightVariable) {
					return {
						left: leftVariable,
						right: rightVariable,
					};
				} else {
					return null;
				}
			})
			.filter((bothVariable) => bothVariable)
			.map((bothVariable) => {
				if (!bothVariable) {
					return null;
				}
				return {
					...bothVariable.left,
					attributes: bothVariable.left.attributes.map((leftAttribute) => {
						const rightAttribute = bothVariable.right?.attributes.find(
							(rightAttribute) =>
								rightAttribute.attribute._id === leftAttribute.attribute._id,
						);

						if (rightAttribute) {
							return leftAttribute;
						} else {
							return null;
						}
					}),
				};
			})
			.filter((variable) => variable?.attributes !== null) as Product['variables'];

	const variationsData = getVariationsData(leftData, rightData);

	return (rightData ? combineVariables(leftData, rightData) : leftData.variables).map(
		(variable) => ({
			...variable,
			attributes: variable.attributes.map((attribute) => {
				const tobeVariation = convertVariations.toVariation(
					[
						...activeOutput.variables.filter(
							(outputVariable) => outputVariable.variable !== variable.variable._id,
						),
						{
							variable: variable.variable._id,
							attribute: attribute.attribute._id,
						},
					],
					variationsData,
				);

				const isAlwaysInactive = !variationsData
					.filter((variation) => variation.active)
					.find((variation) =>
						variation.variants.find(
							(variant) =>
								variant.variable._id === variable.variable._id &&
								variant.attribute._id === attribute.attribute._id,
						),
					);
				const isInactive =
					Boolean(tobeVariation && !tobeVariation.active) || isAlwaysInactive;
				const isSelected = Boolean(
					activeOutput.variables.find(
						(outputVariable) =>
							outputVariable.variable === variable.variable._id &&
							outputVariable.attribute === attribute.attribute._id,
					),
				);
				const select = () => updateVariable(variable.variable._id, attribute.attribute._id);

				return {
					...attribute,
					state: { isInactive, isSelected, select },
				};
			}),
		}),
	);
};
