import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { CartItem, calculateCartItem, cartStorage, cartStorageKey, isDeepEqual } from '..';

const actions = {
	/**
	 * Adds a new item to the cart or updates the quantity if the item already exists.
	 *
	 * @param {Omit<CartItem, 'ref'>} newItem - The new item to be added.
	 * @returns {CartItem} The added or updated item.
	 */
	add: (newItem: Omit<CartItem, 'ref'>): CartItem => {
		const prev = cartStorage.get();

		const duplicate = prev
			.filter((oldItem) => oldItem.output?._data?._id === newItem.output?._data?._id)
			.find((oldItem) => {
				return isDeepEqual(
					{
						output: oldItem.output,
						outputSplit: oldItem.outputSplit,
						outputDeal: oldItem.outputDeal,
					},
					{
						output: newItem.output,
						outputSplit: newItem.outputSplit,
						outputDeal: newItem.outputDeal,
					},
					['timestamp'],
				);
			});

		if (duplicate) {
			return actions.update(duplicate.ref, {
				quantity: duplicate.quantity + newItem.quantity,
			});
		}

		const newItemWithRef = {
			...newItem,
			ref: uuidv4().toString(),
		};

		const result = [newItemWithRef, ...prev];
		cartStorage.set(result);
		return newItemWithRef;
	},
	/**
	 * Updates an item in the cart.
	 *
	 * @param {string} ref - The reference ID of the item to be updated.
	 * @param {Partial<CartItem>} payload - The partial data to update the item with.
	 * @returns {CartItem} The updated item.
	 * @throws {Error} If the item to update is not found in the cart.
	 */
	update: (ref: string, payload: Partial<CartItem>, stayPut?: boolean): CartItem => {
		const prev = cartStorage.get();

		const target = prev.find((item) => item.ref === ref);
		if (!target) {
			throw new Error('Failed to update item in cart, Item not found');
		}

		const updatedItem = {
			...target,
			...payload,
		};

		const result = stayPut
			? prev.map((item) => (item.ref === target.ref ? updatedItem : item))
			: [updatedItem, ...prev.filter((item) => item.ref !== target.ref)];

		cartStorage.set(result);

		return updatedItem;
	},

	/**
	 * Delete an item in the cart.
	 *
	 * @param {string} ref - The reference ID of the item to be deleted.
	 * @returns {CartItem} The updated item.
	 * @throws {Error} If the item to delete is not found in the cart.
	 */
	delete: (ref: string): CartItem | null => {
		const prev = cartStorage.get();

		const updatedItem = prev.find((item) => item.ref === ref);
		if (!updatedItem) {
			throw new Error('Failed to find item in cart');
		}

		const newCart = prev.filter((item) => item.ref !== ref);
		cartStorage.set(newCart);

		return updatedItem;
	},
	/**
	 * Delete all item in the cart.
	 */
	reset: () => {
		cartStorage.set([]);
	},
};

/**
 * Custom hook to retrieve items from the cart.
 *
 * @returns {CartItem[]} The items in the cart.
 */
const useItems = () => {
	const { data: items = [], isLoading } = useSWR(cartStorageKey, () => cartStorage.get());
	return { items, isLoading };
};

/**
 * Custom hook to retrieve items from the cart.
 *
 * @returns The ways to count items in cart.
 */
const useQuantity = () => {
	const { items } = useItems();
	return {
		unique: items.length,
		all: items.map((item) => item.quantity).reduce((a, b) => a + b, 0),
	};
};

/**
 * Custom hook to calculatePrices from the cart.
 *
 * @returns {Price} The price complex of the cart.
 */
// [TODO]: caching cart price
const usePrice = () => {
	const { items } = useItems();
	const price = items
		.map((item) => calculateCartItem(item))
		.reduce(
			(a, b) => ({
				base: {
					final: Math.round(a.base.final + b.base.final),
					previous: Math.round(a.base.previous + b.base.previous),
				},
				extra: {
					final: Math.round(a.extra.final + b.extra.final),
					previous: Math.round(a.extra.previous + b.extra.previous),
				},
				price: {
					final: Math.round(a.price.final + b.price.final),
					previous: Math.round(a.price.previous + b.price.previous),
				},
			}),
			{
				base: {
					final: 0,
					previous: 0,
				},
				extra: {
					final: 0,
					previous: 0,
				},
				price: {
					final: 0,
					previous: 0,
				},
			},
		);

	return price;
};

export const cart = {
	actions,
	useItems,
	usePrice,
	useQuantity,
};
