import { Deal, isDealsAvailable, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Deal[]} Array of Deal data
 */
export const getDeals = (signal?: AbortSignal) => {
	const endpoint = `/deals`;

	return upsellAPI()
		.get<{
			data: Deal[];
		}>(endpoint, { signal })
		.then((res) => prepare(res.data.data));
};

const prepare = (data: Deal[]) => {
	const sortByPosition = (a: Deal, b: Deal) => {
		const getPosition = (pos: number | string | null) =>
			pos === null ? Infinity : typeof pos === 'string' ? parseFloat(pos) : pos;

		const aPos = getPosition(a.position);
		const bPos = getPosition(b.position);
		return aPos - bPos;
	};

	const noInactiveProducts = (data: Deal[]) =>
		data.filter((item) => item.availability.includes('web'));

	return noInactiveProducts(
		data
			.filter((deal) => deal.visibility === 'public' && isDealsAvailable(deal.active_dates))
			.sort((a, b) => (a.name > b.name ? 1 : -1))
			.sort(sortByPosition),
	);
};
