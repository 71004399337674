import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch one branches by ID
 * @param branchID string ID of the branch
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useBranchByID = (branchID: string) =>
	useSWR(!branchID ? null : `branch-by-id-${branchID}`, () => fetchers.getBranchByID(branchID));
