import { TeyaPaymentState } from '../../..';

export const getBAPIToken = async (payment: TeyaPaymentState): Promise<string> =>
	new Promise((resolve, reject) => {
		if (!window.BAPIjs) {
			return reject('BAPIjs is not available');
		}

		window.BAPIjs.getToken(
			{
				pan: payment.card_number,
				expMonth: payment.card_expiry.split('/')[0],
				expYear: payment.card_expiry.split('/')[1],
				cvc: payment.card_cvc,
			},
			(status, data) => {
				if (status.statusCode === 201) {
					return resolve(data.Token);
				} else if (status.statusCode === 401) {
					return reject('Unauthorized received from TeyaPaymentAPI');
				} else if (status.statusCode) {
					return reject(
						`Error received from server ${status.statusCode} - ${status.message}`,
					);
				} else {
					return reject(`Unable to connect to server - ${status.message}`);
				}
			},
		);
	});
