import { errorLog } from '@monorepo/workers/logger';

import { upsellAPI } from '../../..';

type TokenMultiResponse = {
	VirtualNumber: string;
	Token: string;
	PAN: string;
	ExpYear: string;
	ExpMonth: string;
	Enabled: boolean;
	VerifyCardResult: {
		TransactionId: string;
		ActionCode: string;
	};
	Metadata: {
		Payload: string;
	};
};

export const getMultiToken = async (payload: {
	order_id: string;
	token: string;
}): Promise<string> => {
	return upsellAPI()
		.post<{
			data: TokenMultiResponse;
		}>('/payments/teya-rpg/multi-token', payload)
		.then((response) => response.data.data.Token)
		.catch((err) => {
			errorLog({ error: err.message });
			throw err;
		});
};
