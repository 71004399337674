import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch a deal's menus
 * @param dealID string ID of the deal
 * @param menuID string ID of the menu
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useDealItems = (dealID: string | undefined, menuID: string | null) =>
	useSWR(dealID && menuID ? `deal-items-${dealID}-${menuID}` : null, () =>
		fetchers.getMenuData({ dealID: dealID as string, menuID: menuID as string }),
	);
