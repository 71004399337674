import { Product, isProductValid, upsellAPI } from '../..';

/**
 * Function to fetch products based on its category from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Product} Product data
 */
export const getProductByID = (productID: string, signal?: AbortSignal) => {
	const endpoint = `/products/${productID}`;

	return upsellAPI()
		.get<{ data: Product }>(endpoint, { signal })
		.then((res) => isProductValid(res.data.data));
};
