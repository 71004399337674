import { Store, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Store} Store data
 */
export const getStore = (signal?: AbortSignal) => {
	if (typeof window === 'undefined') {
		return;
	}
	const endpoint = `/stores/${window.upsellConfig.storeID}`;
	return upsellAPI()
		.get<{
			data: Store;
		}>(endpoint, { signal })
		.then((res) => res.data.data);
};
