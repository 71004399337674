import { CartItemProduct, Deal, DealItem, Product } from '../..';

export const calculateDiscount = (
	value: number,
	quantity: CartItemProduct['quantity'] | DealItem['quantity'],
	discount?: Product['discount'] | Deal['discount'],
) => {
	if (!discount) {
		return {
			final: Math.round(value * quantity),
			previous: Math.round(value * quantity),
		};
	} else if (discount.type === 'percent') {
		return {
			final: Math.round(((value * (100 - discount.amount)) / 100) * quantity),
			previous: Math.round(value * quantity),
		};
	} else {
		return {
			final: Math.round((value - discount.amount) * quantity),
			previous: Math.round(value * quantity),
		};
	}
};
