import { ProductExtended, convertVariations, isDeepEqual } from '..';

/**
 * Removes unselectable items from the provided list based on available variations.
 *
 * @param {ProductExtended[]} items - The list of items to filter.
 * @returns {ProductExtended[]} The filtered list containing only selectable items.
 */
export const removeUnselectable = (items: ProductExtended[]) => {
	const isSelectable = (item: ProductExtended) => {
		if (Array.isArray(item.available_variations) && item.available_variations.length === 0) {
			return true;
		}

		const availableVariations = item.available_variations.map((avbg) =>
			avbg.variants.map((avb) => ({
				variable: avb.variant,
				attribute: avb.attribute,
			})),
		);

		return item.variations.find((variation) => {
			if (!variation.active) {
				return false;
			}
			const itemVariations = convertVariations.toVariables(variation);
			return availableVariations.find((avbs) => isDeepEqual(avbs, itemVariations));
		});
	};

	return items.filter(isSelectable);
};
