import useSWR, { mutate } from 'swr';

import { GuestToken, tokenStorage } from '../..';

export const useGuestState = () => {
	const { data: guest } = useSWR('guest', () => {
		const { guestToken } = tokenStorage.get();
		return guestToken;
	});

	const updateGuestField = (key: 'name' | 'address' | 'email' | 'phone', value: string) => {
		if (guest) {
			if (key === 'name') {
				tokenStorage.save({
					guestToken: {
						...guest,
						name: value,
					},
				});
			} else if (key === 'address') {
				tokenStorage.save({
					guestToken: {
						...guest,
						address: value,
					},
				});
			} else if (key === 'email') {
				tokenStorage.save({
					guestToken: {
						...guest,
						email: {
							...guest.email,
							address: value,
						},
					},
				});
			} else if (key === 'phone') {
				tokenStorage.save({
					guestToken: {
						...guest,
						phone: value,
					},
				});
			}
		}
		mutate('guest');
	};

	const setGuest = (guest: GuestToken): void => {
		tokenStorage.save({
			guestToken: guest,
		});
		mutate('guest');
	};

	return {
		guest,
		updateGuestField,
		setGuest,
	};
};
