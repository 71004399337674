import { mutate } from 'swr';

import { coupon } from '.';

/**
 * Redeems a gift card with the specified gift card code.
 * @param couponCode - The code of the gift card to be redeemed.
 * @returns A promise that resolves to the redeemed gift card data.
 * @throws An error if the gift card redemption fails.
 */
export const redeem = async (couponCode: string) =>
	coupon.get(couponCode).then((couponData) => {
		window.couponCode = couponCode;
		mutate('coupon', couponData);
	});

declare global {
	interface Window {
		couponCode: string;
	}
}
