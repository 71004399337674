import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch a products by ID
 * @param productID string ID of the product
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useProductByID = (productID: string) =>
	useSWR(!productID ? null : `product-by-id-${productID}`, () =>
		fetchers.getProductByID(productID),
	);
