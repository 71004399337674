import { PaymentState } from '..';

export const initialState: Record<PaymentState['payment_method'], PaymentState> = {
	giftcard: {
		payment_method: 'giftcard',
	},
	wolt: {
		payment_method: 'wolt',
	},
	cash: {
		payment_method: 'cash',
	},
	netgiro: {
		payment_method: 'netgiro',
		customer_id: '',
	},
	aur: {
		payment_method: 'aur',
		phone: '',
	},
	rapyd: {
		card_number: '',
		card_expiration: '',
		card_cvv: '',
		name: '',
		payment_method: 'rapyd',
	},
	'teya-rpg': {
		card_number: '',
		card_expiry: '',
		card_cvc: '',
		public_token: '',
		payment_method: 'teya-rpg',
	},
	'apple-pay': {
		payment_method: 'apple-pay',
		applicationPrimaryAccountNumber: '',
		applicationExpirationDate: '',
		paymentData: { onlinePaymentCryptogram: '', eciIndicator: '' },
		currencyCode: '',
		transactionAmount: 0,
		deviceManufacturerIdentifier: '',
		paymentDataType: '',
	},
};
