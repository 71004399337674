import * as swr from 'swr';

import { fetchers } from '..';

/**
 * Preloads data using SWR for efficient server-side rendering.
 * Fetches store, categories, deals, products, and related data.
 */
export const preload = async () => {
	const store = await fetchers.getStore();
	swr.preload('store', () => store);

	const categories = await fetchers.getCategories();
	swr.preload('categories', () => categories);

	const products = await fetchers.getProducts();
	swr.preload('products', () => products);

	products.forEach((product) => {
		swr.preload(`product-by-id-${product._id}`, () => product);
		// // output._data
		// isDeepEqual(product, output._data)
	});

	const deals = await fetchers.getDeals();
	swr.preload('deals', () => deals);

	deals.forEach((deal) => {
		swr.preload(`deal-by-id-${deal._id}`, () => deal);
	});

	categories.forEach((category) => {
		swr.preload(`category-by-id-${category._id}`, () => category);

		const relatedProducts = products?.filter((item) => {
			return item.categories.find((c) => {
				if (c.category === null) {
					return false;
				} else {
					return c.category._id.toLowerCase() === category._id;
				}
			});
		});

		swr.preload(`products-by-category-${category._id}`, () => relatedProducts);
		swr.preload(`products-by-category-slug-${category.slug}`, () => relatedProducts);
	});

	const branches = await fetchers.getBranches();
	swr.preload('branches', () => branches);

	branches.forEach((branch) => {
		swr.preload(`branch-by-id-${branch._id}`, () => branch);
	});
};
