// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { errorLog } from '@monorepo/workers/logger';
import { useRouter } from 'next/navigation';

import upsell from '../../..';
import { usePriceSummary } from '../../..';
import { decryptToken } from './decryptToken';
import { validateMerchant } from './validateMerchant';

/**
 * Docs:
 * https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/creating_an_apple_pay_session
 */
export const useApplePay = () => {
	const router = useRouter();
	const { checkout } = upsell.provider.checkout.useContext();
	const { total } = usePriceSummary();
	const { data: store } = upsell.data.useStore();

	const checkoutWithApplePay = () => {
		if (
			typeof window.ApplePaySession === 'undefined' ||
			(typeof window.ApplePaySession.canMakePayments === 'function' &&
				!window.ApplePaySession.canMakePayments())
		) {
			return alert('Apple pay is not available on this browser');
		}

		const session = new window.ApplePaySession(3, {
			countryCode: 'IS', // TOOD : make it dynamic
			currencyCode: 'ISK', // TOOD : make it dynamic
			supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
			merchantCapabilities: ['supports3DS'],
			total: {
				label: store?.business_name,
				amount: total.toString(),
			},
		});

		session.onvalidatemerchant = (event: Window['ApplePaySession']['onvalidatemerchant']) => {
			validateMerchant(event.validationURL)
				.then((merchantSession) => {
					session.completeMerchantValidation(merchantSession);
				})
				.catch((err) => {
					errorLog({ error: err.message });
					return null;
				});
		};

		session.onpaymentmethodselected = () => {
			const newTotal = {
				type: 'final',
				label: store?.business_name,
				amount: total,
			};
			session.completePaymentMethodSelection(newTotal, []);
		};

		session.onpaymentauthorized = (event: Window['ApplePaySession']['onpaymentauthorized']) => {
			decryptToken(event.payment.token)
				.then((payment) => {
					session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
					/**
					 * Force payment method to 'apple-pay' temporarily
					 * then back again to 'teya-rpg' after handleApplePayment is called
					 */
					return checkout({
						payment: { ...payment, payment_method: 'apple-pay' },
						callback: (paymentResult, order) => {
							if (
								typeof paymentResult !== 'undefined' &&
								'TransactionStatus' in paymentResult &&
								paymentResult.TransactionStatus !== 'Accepted'
							) {
								alert(
									'Failed, transaction status is ' +
										paymentResult.TransactionStatus,
								);
								return;
							}
							router.push(`/checkout/success?order=${order._id}`); // [TODO]: wrong redirection here
						},
					});
				})
				.catch((err) => {
					errorLog({ error: err.message });
					session.completePayment({
						status: window.ApplePaySession.STATUS_FAILURE,
						errors: [err.message],
					});
				});
		};

		session.oncancel = (event: Window['ApplePaySession']['oncancel']) => {
			errorLog({ error: 'Apple payment cancelled: ' + event });
		};

		session.begin();
	};
	return { checkoutWithApplePay };
};

// @ts-check
