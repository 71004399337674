import { mutate } from 'swr';

import { UpdateUserPayload, User, tokenStorage, upsellAPI } from '..';

/**
 * Update a user data
 * @param {UpdateUserPayload} payload - Payload of country code, phone number, name and email
 * @returns {Promise<{user: User, status: 'Success' | unknown}>} Response a user data and user status
 */
export const update = (payload: UpdateUserPayload) => {
	const { accessToken } = tokenStorage.get();

	return upsellAPI()
		.put<{
			user: User;
			status: 'Success' | unknown;
		}>(`/customers/update/profile`, payload, {
			headers: {
				Authorization: 'Bearer ' + accessToken,
			},
		})
		.then((res) => {
			mutate('user', res.data.user);
			return res.data;
		});
};
