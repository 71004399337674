import { CartItemDeal, Price, calculateDealItem, calculateDiscount } from '../../../../';

/*
	Currently there are below the known types of deals pricing
		1. Fixed price: it follows the base_price, no matter what the choices are
		2. Choice based price: base_price is added with each menu's dynamic price
		3. Rules based price: pricing are unique to each rules promotional concept
	And for all types, extras are still charged
*/

export const calculateDeal = (cartItemDeal: CartItemDeal): Price => {
	if (cartItemDeal.outputDeal._data.deals_rules) {
		throw new Error(`Feature under development, deals rules are not available yet`);
	} else {
		const dealItemPrices = cartItemDeal.outputDeal.items.map((dealItem) =>
			calculateDealItem(dealItem),
		);

		const base = (() => {
			const fixed = (() => {
				const basePrice = cartItemDeal.outputDeal._data.base_price;
				const quantity = cartItemDeal.quantity;
				const discount = cartItemDeal.outputDeal._data.discount;
				return calculateDiscount(basePrice, quantity, discount);
			})();

			const dynamic = dealItemPrices
				.map((dealItemPrice) => dealItemPrice.base)
				.reduce(
					(a, b) => ({
						final: Math.round(a.final + b.final),
						previous: Math.round(a.previous + b.previous),
					}),
					{
						final: 0,
						previous: 0,
					},
				);

			return [fixed, dynamic].reduce(
				(a, b) => ({
					final: Math.round(a.final + b.final),
					previous: Math.round(a.previous + b.previous),
				}),
				{
					final: 0,
					previous: 0,
				},
			);
		})();

		const extra = dealItemPrices
			.map((dealItemPrice) => dealItemPrice.extra)
			.reduce(
				(a, b) => ({
					final: Math.round(a.final + b.final),
					previous: Math.round(a.previous + b.previous),
				}),
				{
					final: 0,
					previous: 0,
				},
			);

		const price = [base, extra].reduce(
			(a, b) => ({
				final: Math.round(a.final + b.final),
				previous: Math.round(a.previous + b.previous),
			}),
			{
				final: 0,
				previous: 0,
			},
		);

		return {
			base,
			extra,
			price,
		};
	}
};
