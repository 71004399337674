import { Product } from '..';

export const filterInvalidExtras = (
	extras: Product['extras'],
	product: Product,
): Product['extras'] => {
	return extras.filter((extra) => {
		const item = extra.items[0];
		const isVariantPricing = item.variant_pricing;

		if (isVariantPricing) {
			const variant = extra.items[0].variants[0];
			const variableDependency = variant.variant_item.variable;

			const isDependencyFound = product.variables.find(
				(variable) => variable.variable._id === variableDependency,
			);

			return isDependencyFound;
		} else {
			return true;
		}
	});
};
