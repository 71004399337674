import { SubPrice } from '../../../../..';

export const calculateAdditionalPrice = (
	type: 'percent' | 'percentage' | 'price' | 'fixed',
	value: number,
	base: SubPrice,
): SubPrice => {
	if (type === 'percent') {
		return {
			final: Math.round((value / 100) * base.final),
			previous: Math.round((value / 100) * base.previous),
		};
	} else if (type === 'percentage') {
		return {
			final: Math.round(((100 - value) / 100) * base.final),
			previous: Math.round(((100 - value) / 100) * base.previous),
		};
	} else if (type === 'price') {
		return {
			final: Math.round(base.final - value),
			previous: Math.round(base.previous - value),
		};
	} else if (type === 'fixed') {
		return {
			final: Math.round(value),
			previous: Math.round(value),
		};
	} else {
		throw new Error(`Invalid Additional Price type: ${type}`);
	}
};
