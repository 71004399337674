import { Order } from '../..';

export const isOrderExpired = (order: Order, ms = 3600000) => {
	const { createdAt } = order;

	const now = new Date();

	const diff = now.getTime() - new Date(createdAt).getTime();

	return diff > ms;
};
