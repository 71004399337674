/**
 * Converts a number to currency format with custom options.
 *
 * @param {number} number - The number to be formatted.
 * @param {string} [delimiter='.'] - The character used as a thousands separator.
 * @param {string} [currency='Kr.'] - The currency symbol.
 * @param {boolean} [currencyPositionInLeft=false] - Specifies whether the currency symbol appears to the left or right of the formatted number.
 * @returns {string} The formatted currency string.
 */
export const toCurrency = (
	number: number,
	delimiter = '.',
	currency = 'Kr.',
	currencyPositionInLeft = false,
): string => {
	if (number === null) {
		return 'null';
	}
	if (isNaN(number)) {
		return 'NaN';
	}
	// Convert the number to a string with 1 decimal point
	const formattedNumber = number.toFixed(1);

	// Split the number into integer and decimal parts
	const [integerPart, decimalPart] = formattedNumber.split('.');

	// Remove ".0" if the decimal part is "0"
	const cleanedDecimalPart = decimalPart === '0' ? '' : `,${decimalPart}`;

	// Format the integer part with thousands separator
	const integerWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

	// Combine the integer and decimal parts
	const formattedValue = cleanedDecimalPart
		? `${integerWithSeparator}${cleanedDecimalPart}`
		: integerWithSeparator;

	// Combine the currency and value based on currencyPositionInLeft
	if (currencyPositionInLeft) {
		return `${currency} ${formattedValue}`;
	} else {
		return `${formattedValue} ${currency}`;
	}
};
