import { Product, filterInvalidExtras, upsellAPI } from '../..';

/**
 * Function to fetch products based on its category from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Product[]} Array of Products data
 */
export const getProducts = (signal?: AbortSignal) => {
	const endpoint = `/products`;

	return upsellAPI()
		.get<{ data: Product[] }>(endpoint, { signal })
		.then((res) => prepare(res.data.data));
};

const prepare = (data: Product[]) => {
	const noInvalidExtras = (data: Product[]) => {
		return data.map((item) => ({
			...item,
			extras: filterInvalidExtras(item.extras, item),
		}));
	};

	const noNullCategory = (data: Product[]) => {
		return data.map((item) => ({
			...item,
			categories: item.categories.filter((c) => c.category !== null),
		}));
	};

	const noInactiveProducts = (data: Product[]) =>
		data.filter((item) => item.product_availability.web_availability);

	const sorter = (data: Product[]) => data.sort((a, b) => (a.name > b.name ? 1 : -1));

	return sorter(noNullCategory(noInactiveProducts(noInvalidExtras(data))));
};
