import { ApplePayDecryptResponse, upsellAPI } from '../../..';

type PostApplePayDecryptResponse = ApplePayDecryptResponse & {
	payment_method: 'apple-pay';
};

type PaymentToken = {
	paymentData: {
		version: string;
		data: string;
		signature: string;
		header: {
			ephemeralPublicKey: string;
			publicKeyHash: string;
			transactionId: string;
		};
	};
	paymentMethod: {
		displayName: string;
		network: string;
		type: string;
	};
	transactionIdentifier: string;
};

/**
 * Decrypts Apple Pay payment token
 * @param {ApplePayPaymentTokenPayload} paymentToken - Apple Pay payment token payload
 * @returns {Promise<{data: PostApplePayDecryptResponse}>} Resolves with decrypted payment data
 */
export const decryptToken = (paymentToken: PaymentToken) =>
	upsellAPI()
		.post<{
			data: PostApplePayDecryptResponse;
		}>('/payments/apple-pay/payment-decrypt', paymentToken.paymentData)
		.then((res) => res.data.data);
