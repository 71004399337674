import { Category, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Category} Category data
 */
export const getCategoryByID = (categoryID: string, signal?: AbortSignal) => {
	const endpoint = `/categories/${categoryID}?populate=categories`;

	return upsellAPI()
		.get<{
			data: Category;
		}>(endpoint, { signal })
		.then((res) => res.data.data);
};
