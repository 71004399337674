import { guest } from './guest';
import { logout } from './logout';
import { otp } from './otp';
import { register } from './register';
import { update } from './update';

export const auth = {
	otp,
	logout,
	register,
	update,
	guest,
};
