// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Input = any;

/**
 * Checks if two inputs are deeply equal.
 *
 * @param {Input} a - The first input to compare.
 * @param {Input} b - The second input to compare.
 * @returns {boolean} True if the inputs are deeply equal, false otherwise.
 */
export const isDeepEqual = (a: Input, b: Input, ignoredKeys?: string[]): boolean => {
	if (a === b) {
		return true;
	}
	if (a == null || typeof a != 'object' || b == null || typeof b != 'object') {
		return false;
	}
	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	if (keysA.length != keysB.length) {
		return false;
	}
	for (const key of keysA) {
		if (!ignoredKeys || (ignoredKeys && !ignoredKeys.includes(key))) {
			if (!keysB.includes(key) || !isDeepEqual(a[key], b[key], ignoredKeys)) {
				return false;
			}
		}
	}
	return true;
};
