import { mutate } from 'swr';

import { User, VerifyOtpPayload, tokenStorage, upsellAPI } from '../..';

/**
 * Verify OTP for user authentication
 * @param {VerifyOtpPayload} payload - Payload of phone number, otp, and is_update
 * @returns {Promise<{isRegistered: boolean}>} Response a boolean value `isRegistered` to determine if the user already registered
 */
export const verify = (payload: VerifyOtpPayload) =>
	upsellAPI()
		.post<{
			data: {
				user: User;
				user_status: string;
				access_token: string;
				refresh_token: string;
			};
		}>(`/auth/customers/upsell-otp/verify`, payload)
		.then(async (res) => {
			tokenStorage.save({
				accessToken: res.headers['x-token'],
				refreshToken: res.headers['x-refresh-token'],
			});

			const isRegistered =
				res.data.data.user_status === 'registered' && Boolean(res.data.data.user.name);

			if (isRegistered) {
				mutate('user', res.data.data.user);
			}

			return {
				isRegistered,
			};
		});
