import { Branch, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {string} branchID? - branch ID to fetch
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Branch} a Branch data
 */
export const getBranchByID = (branchID: string, signal?: AbortSignal) => {
	const endpoint = `/branches/${branchID}/`;

	return upsellAPI()
		.get<{
			data: Branch;
		}>(endpoint, { signal })
		.then((res) => res.data.data);
};
