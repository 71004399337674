import upsell, { calculateDealItem, convertVariations, getVariationsData } from '../..';

/**
 * Retrieves an variable data from one or two sets of data for the available variables.
 *
 * @returns {ProductExtended[]} The combined and labeled variables data.
 */
export const useDealItemsState = (dealID: string, menuID: string) => {
	const { outputDeal } = upsell.provider.customization.useContext();
	const { data: products } = upsell.data.useDealItems(dealID, menuID);
	if (!products) {
		return [];
	}

	return products.map((product) => {
		const variationsData = getVariationsData(product);

		const prices = (() => {
			if (product.variations.length > 0) {
				return variationsData
					.filter((variation) => variation.active)
					.map(
						(variation) =>
							calculateDealItem({
								menuID: '',
								quantity: product.quantity,
								output: {
									_data: product,
									variables: convertVariations.toVariables(variation),
									extras: [],
								},
								outputSplit: null,
							}).price.final,
					)
					.sort((a, b) => a - b);
			} else {
				const dealItem = calculateDealItem({
					menuID: '',
					quantity: product.quantity,
					output: {
						_data: product,
						variables: [],
						extras: [],
					},
					outputSplit: null,
				});

				return [dealItem.price.final];
			}
		})();

		const isSelected = Boolean(
			outputDeal?.items.find(
				(item) => item.menuID === menuID && item.output._data._id === product._id,
			),
		);

		return {
			...product,
			state: { prices, isSelected },
		};
	});
};
