import { Output, Product } from '..';

export const preselectVariablesPartially = (
	variationsData: Product['variations'],
	variablesData: Product['variables'],
	variables: Output['variables'],
) => {
	if (variationsData.length === 0) {
		return [];
	}

	return variables.map((variable) => {
		const variableData = variablesData.find(
			(variableData) => variableData.variable._id === variable.variable,
		);
		if (!variableData) {
			throw new Error('Variable data not found');
		}
		const activeAttributes = variableData.attributes.filter((attributeData) => {
			return variationsData
				.filter((variation) => variation.active)
				.find((variation) =>
					variation.variants.find(
						(variant) =>
							variant.variable._id === variableData.variable._id &&
							variant.attribute._id === attributeData.attribute._id,
					),
				);
		});

		const isTheOnlyChoice = activeAttributes.length === 1;
		if (isTheOnlyChoice) {
			return {
				variable: variable.variable,
				attribute: activeAttributes[0].attribute._id,
			};
		} else if (variable.attribute) {
			const isAlwaysInactive = !variationsData
				.filter((variation) => variation.active)
				.find((variation) =>
					variation.variants.find(
						(variant) =>
							variant.variable._id === variable.variable &&
							variant.attribute._id === variable.attribute,
					),
				);

			if (isAlwaysInactive) {
				return {
					variable: variable.variable,
					attribute: '',
				};
			} else {
				return variable;
			}
		} else {
			return variable;
		}
	});
};
