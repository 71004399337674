import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch all payment methods
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const usePaymentMethods = () =>
	useSWR('payment-methods', () => fetchers.getPaymentMethods());
