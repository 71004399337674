import { errorLog } from '@monorepo/workers/logger';
import { mutate } from 'swr';

import { GiftcardItemType, tokenStorage, upsellAPI } from '../..';

/**
 * Redeems a gift card with the specified gift card code.
 * @param giftcardCode - The code of the gift card to be redeemed.
 * @returns A promise that resolves to the redeemed gift card data.
 * @throws An error if the gift card redemption fails.
 */
export const redeem = async (giftcardCode: string) => {
	const { accessToken } = tokenStorage.get();
	if (!accessToken) {
		return undefined;
	}

	const payload = {
		giftcard_code: giftcardCode,
	};

	return upsellAPI()
		.put<{
			data: GiftcardItemType;
		}>('/giftcards/redeem', payload, {
			headers: {
				user: accessToken,
			},
		})
		.then((res) => {
			mutate('giftcards');
			return res.data.data;
		})
		.catch((err) => {
			errorLog({ error: err.message });
			throw new Error('Failed to redeem gift card');
		});
};
