import { auth } from './auth';
import { cart } from './cart';
import { data } from './data';
import { init } from './init';
import { payment } from './payment';
import { provider } from './provider';

const upsell = {
	auth,
	cart,
	data,
	init,
	payment,
	provider,
};

export default upsell;

export * from './@types';
export * from './lib';
