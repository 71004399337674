import { errorLog } from '@monorepo/workers/logger';

import { Order, isOrderExpired, populateItemsAsCart, upsellAPI } from '../..';

export type OrderByIDConfig = {
	checkExpiration?: boolean;
	refreshInterval?: number;
};

/**
 * Function to fetch success order by ID from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Order} Order data
 */
export const getOrderByID = (
	orderID: string,
	config: OrderByIDConfig = {},
	signal?: AbortSignal,
) => {
	if (!orderID) {
		throw new Error('Order ID is required');
	}

	const { checkExpiration } = config;

	const endpoint = `/orders/${orderID}`;

	return upsellAPI()
		.get<{
			data: Order;
		}>(endpoint, { signal })
		.then((res) => {
			const order = res.data.data;

			if (checkExpiration && isOrderExpired(order)) {
				throw new Error('Order expired');
			}

			return order;
		})
		.then(async (order) => await populateItemsAsCart(order))
		.catch((err) => {
			errorLog({ error: err.message });
			throw new Error('Failed to get success order');
		});
};
