import { CartItemProductSingle, convertVariations } from '../../..';
import { formatExtras } from './formatExtras';

export type OrderItemProductSingle = {
	product_id: string;
	product_variation?: string;
	quantity: number;
	item_note: string;
	extras: Extra[];
};

export type Extra = {
	extras_id: string;
	items: ExtraItem[];
};

type ExtraItem = {
	extras_variant_item_id?: string;
	extras_item_id: string;
	quantity: number;
};

export const convertProductSingle = (
	cartItemProductSingle: CartItemProductSingle,
): OrderItemProductSingle => {
	const { output, quantity } = cartItemProductSingle;

	const product_id = output._data._id;

	const product_variation = convertVariations.toVariation(
		output.variables,
		output._data.variations,
	)?._id;

	const extras: Extra[] = formatExtras(output);

	const item_note = ''; // [TODO]: make this a feature

	if (!product_variation) {
		return {
			product_id,
			quantity,
			extras,
			item_note,
		};
	}

	return {
		product_id,
		product_variation,
		quantity,
		extras,
		item_note,
	};
};
