import { upsellAPI } from '../..';

export type MerchantValidationPayload = {
	validationUrl: string;
	host: string;
	displayName: string;
};

export type MerchantValidationResponse = {
	epochTimestamp: number;
	expiresAt: number;
	merchantSessionIdentifier: string;
	nonce: string;
	merchantIdentifier: string;
	domainName: string;
	displayName: string;
	signature: string;
	operationalAnalyticsIdentifier: string;
	retries: number;
	pspId: string;
};

/**
 * Performs merchant validation for Apple Pay
 * @param {MerchantValidationPayload} validationUrl - The payload containing merchant validation data
 * @returns {Promise<{data: MerchantValidationResponse}>} Resolves with the validation response
 */
export const validateMerchant = (validationUrl: MerchantValidationPayload['validationUrl']) =>
	upsellAPI()
		.post<{
			data: MerchantValidationResponse;
		}>('/payments/apple-pay/merchant-validation', {
			validationUrl: validationUrl,
			host: window.location.hostname,
		})
		.then((res) => res.data.data);
