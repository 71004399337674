export const COUNTRY_CODES = [
	{ flag: 'AD', code: '+376', value: '+376 ANDORRA', fullname: 'ANDORRA' },
	{
		flag: 'AE',
		code: '+971',
		value: '+971 UNITED ARAB EMIRATES',
		fullname: 'UNITED ARAB EMIRATES',
	},
	{
		flag: 'AF',
		code: '+93',
		value: '+93 AFGHANISTAN',
		fullname: 'AFGHANISTAN',
	},
	{
		flag: 'AG',
		code: '+1268',
		value: '+1268 ANTIGUA AND BARBUDA',
		fullname: 'ANTIGUA AND BARBUDA',
	},
	{ flag: 'AI', code: '+1264', value: '+1264 ANGUILLA', fullname: 'ANGUILLA' },
	{ flag: 'AL', code: '+355', value: '+355 ALBANIA', fullname: 'ALBANIA' },
	{ flag: 'AM', code: '+374', value: '+374 ARMENIA', fullname: 'ARMENIA' },
	{
		flag: 'AN',
		code: '+599',
		value: '+599 NETHERLANDS ANTILLES',
		fullname: 'NETHERLANDS ANTILLES',
	},
	{ flag: 'AO', code: '+244', value: '+244 ANGOLA', fullname: 'ANGOLA' },
	{ flag: '', code: '+672', value: '+672 ANTARCTICA', fullname: 'ANTARCTICA' },
	{ flag: 'AR', code: '+54', value: '+54 ARGENTINA', fullname: 'ARGENTINA' },
	{
		flag: 'AS',
		code: '+1684',
		value: '+1684 AMERICAN SAMOA',
		fullname: 'AMERICAN SAMOA',
	},
	{ flag: 'AT', code: '+43', value: '+43 AUSTRIA', fullname: 'AUSTRIA' },
	{ flag: 'AU', code: '+61', value: '+61 AUSTRALIA', fullname: 'AUSTRALIA' },
	{ flag: 'AW', code: '+297', value: '+297 ARUBA', fullname: 'ARUBA' },
	{
		flag: 'AZ',
		code: '+994',
		value: '+994 AZERBAIJAN',
		fullname: 'AZERBAIJAN',
	},
	{
		flag: 'BA',
		code: '+387',
		value: '+387 BOSNIA AND HERZEGOVINA',
		fullname: 'BOSNIA AND HERZEGOVINA',
	},
	{ flag: 'BB', code: '+1246', value: '+1246 BARBADOS', fullname: 'BARBADOS' },
	{
		flag: 'BD',
		code: '+880',
		value: '+880 BANGLADESH',
		fullname: 'BANGLADESH',
	},
	{ flag: 'BE', code: '+32', value: '+32 BELGIUM', fullname: 'BELGIUM' },
	{
		flag: 'BF',
		code: '+226',
		value: '+226 BURKINA FASO',
		fullname: 'BURKINA FASO',
	},
	{ flag: 'BG', code: '+359', value: '+359 BULGARIA', fullname: 'BULGARIA' },
	{ flag: 'BH', code: '+973', value: '+973 BAHRAIN', fullname: 'BAHRAIN' },
	{ flag: 'BI', code: '+257', value: '+257 BURUNDI', fullname: 'BURUNDI' },
	{ flag: 'BJ', code: '+229', value: '+229 BENIN', fullname: 'BENIN' },
	{
		flag: '',
		code: '+590',
		value: '+590 SAINT BARTHELEMY',
		fullname: 'SAINT BARTHELEMY',
	},
	{ flag: 'BM', code: '+1441', value: '+1441 BERMUDA', fullname: 'BERMUDA' },
	{
		flag: 'BN',
		code: '+673',
		value: '+673 BRUNEI DARUSSALAM',
		fullname: 'BRUNEI DARUSSALAM',
	},
	{ flag: 'BO', code: '+591', value: '+591 BOLIVIA', fullname: 'BOLIVIA' },
	{ flag: 'BR', code: '+55', value: '+55 BRAZIL', fullname: 'BRAZIL' },
	{ flag: 'BS', code: '+1242', value: '+1242 BAHAMAS', fullname: 'BAHAMAS' },
	{ flag: 'BT', code: '+975', value: '+975 BHUTAN', fullname: 'BHUTAN' },
	{ flag: 'BW', code: '+267', value: '+267 BOTSWANA', fullname: 'BOTSWANA' },
	{ flag: 'BY', code: '+375', value: '+375 BELARUS', fullname: 'BELARUS' },
	{ flag: 'BZ', code: '+501', value: '+501 BELIZE', fullname: 'BELIZE' },
	{ flag: 'CA', code: '+1', value: '+1 CANADA', fullname: 'CANADA' },
	{
		flag: 'CC',
		code: '+61',
		value: '+61 COCOS (KEELING) ISLANDS',
		fullname: 'COCOS (KEELING) ISLANDS',
	},
	{
		flag: 'CD',
		code: '+243',
		value: '+243 CONGO, THE DEMOCRATIC REPUBLIC OF THE',
		fullname: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
	},
	{
		flag: 'CF',
		code: '+236',
		value: '+236 CENTRAL AFRICAN REPUBLIC',
		fullname: 'CENTRAL AFRICAN REPUBLIC',
	},
	{ flag: 'CG', code: '+242', value: '+242 CONGO', fullname: 'CONGO' },
	{
		flag: 'CH',
		code: '+41',
		value: '+41 SWITZERLAND',
		fullname: 'SWITZERLAND',
	},
	{
		flag: 'CI',
		code: '+225',
		value: '+225 COTE D IVOIRE',
		fullname: 'COTE D IVOIRE',
	},
	{
		flag: 'CK',
		code: '+682',
		value: '+682 COOK ISLANDS',
		fullname: 'COOK ISLANDS',
	},
	{ flag: 'CL', code: '+56', value: '+56 CHILE', fullname: 'CHILE' },
	{ flag: 'CM', code: '+237', value: '+237 CAMEROON', fullname: 'CAMEROON' },
	{ flag: 'CN', code: '+86', value: '+86 CHINA', fullname: 'CHINA' },
	{ flag: 'CO', code: '+57', value: '+57 COLOMBIA', fullname: 'COLOMBIA' },
	{
		flag: 'CR',
		code: '+506',
		value: '+506 COSTA RICA',
		fullname: 'COSTA RICA',
	},
	{ flag: 'CU', code: '+53', value: '+53 CUBA', fullname: 'CUBA' },
	{
		flag: 'CV',
		code: '+238',
		value: '+238 CAPE VERDE',
		fullname: 'CAPE VERDE',
	},
	{
		flag: 'CX',
		code: '+61',
		value: '+61 CHRISTMAS ISLAND',
		fullname: 'CHRISTMAS ISLAND',
	},
	{ flag: 'CY', code: '+357', value: '+357 CYPRUS', fullname: 'CYPRUS' },
	{
		flag: 'CZ',
		code: '+420',
		value: '+420 CZECH REPUBLIC',
		fullname: 'CZECH REPUBLIC',
	},
	{ flag: 'DE', code: '+49', value: '+49 GERMANY', fullname: 'GERMANY' },
	{ flag: 'DJ', code: '+253', value: '+253 DJIBOUTI', fullname: 'DJIBOUTI' },
	{ flag: 'DK', code: '+45', value: '+45 DENMARK', fullname: 'DENMARK' },
	{ flag: 'DM', code: '+1767', value: '+1767 DOMINICA', fullname: 'DOMINICA' },
	{
		flag: 'DO',
		code: '+1809',
		value: '+1809 DOMINICAN REPUBLIC',
		fullname: 'DOMINICAN REPUBLIC',
	},
	{ flag: 'DZ', code: '+213', value: '+213 ALGERIA', fullname: 'ALGERIA' },
	{ flag: 'EC', code: '+593', value: '+593 ECUADOR', fullname: 'ECUADOR' },
	{ flag: 'EE', code: '+372', value: '+372 ESTONIA', fullname: 'ESTONIA' },
	{ flag: 'EG', code: '+20', value: '+20 EGYPT', fullname: 'EGYPT' },
	{ flag: 'ER', code: '+291', value: '+291 ERITREA', fullname: 'ERITREA' },
	{ flag: 'ES', code: '+34', value: '+34 SPAIN', fullname: 'SPAIN' },
	{ flag: 'ET', code: '+251', value: '+251 ETHIOPIA', fullname: 'ETHIOPIA' },
	{ flag: 'FI', code: '+358', value: '+358 FINLAND', fullname: 'FINLAND' },
	{ flag: 'FJ', code: '+679', value: '+679 FIJI', fullname: 'FIJI' },
	{
		flag: 'FK',
		code: '+500)',
		value: '+500 FALKLAND ISLANDS (MALVINAS)',
		fullname: 'FALKLAND ISLANDS (MALVINAS)',
	},
	{
		flag: 'FM',
		code: '+691',
		value: '+691 MICRONESIA, FEDERATED STATES OF',
		fullname: 'MICRONESIA, FEDERATED STATES OF',
	},
	{
		flag: 'FO',
		code: '+298',
		value: '+298 FAROE ISLANDS',
		fullname: 'FAROE ISLANDS',
	},
	{ flag: 'FR', code: '+33', value: '+33 FRANCE', fullname: 'FRANCE' },
	{ flag: 'GA', code: '+241', value: '+241 GABON', fullname: 'GABON' },
	{
		flag: 'GB',
		code: '+44',
		value: '+44 UNITED KINGDOM',
		fullname: 'UNITED KINGDOM',
	},
	{ flag: 'GD', code: '+1473', value: '+1473 GRENADA', fullname: 'GRENADA' },
	{ flag: 'GE', code: '+995', value: '+995 GEORGIA', fullname: 'GEORGIA' },
	{ flag: 'GH', code: '+233', value: '+233 GHANA', fullname: 'GHANA' },
	{ flag: 'GI', code: '+350', value: '+350 GIBRALTAR', fullname: 'GIBRALTAR' },
	{ flag: 'GL', code: '+299', value: '+299 GREENLAND', fullname: 'GREENLAND' },
	{ flag: 'GM', code: '+220', value: '+220 GAMBIA', fullname: 'GAMBIA' },
	{ flag: 'GN', code: '+224', value: '+224 GUINEA', fullname: 'GUINEA' },
	{
		flag: 'GQ',
		code: '+240',
		value: '+240 EQUATORIAL GUINEA',
		fullname: 'EQUATORIAL GUINEA',
	},
	{ flag: 'GR', code: '+30', value: '+30 GREECE', fullname: 'GREECE' },
	{ flag: 'GT', code: '+502', value: '+502 GUATEMALA', fullname: 'GUATEMALA' },
	{ flag: 'GU', code: '+1671', value: '+1671 GUAM', fullname: 'GUAM' },
	{
		flag: 'GW',
		code: '+245',
		value: '+245 GUINEA-BISSAU',
		fullname: 'GUINEA-BISSAU',
	},
	{ flag: 'GY', code: '+592', value: '+592 GUYANA', fullname: 'GUYANA' },
	{ flag: 'HK', code: '+852', value: '+852 HONG KONG', fullname: 'HONG KONG' },
	{ flag: 'HN', code: '+504', value: '+504 HONDURAS', fullname: 'HONDURAS' },
	{ flag: 'HR', code: '+385', value: '+385 CROATIA', fullname: 'CROATIA' },
	{ flag: 'HT', code: '+509', value: '+509 HAITI', fullname: 'HAITI' },
	{ flag: 'HU', code: '+36', value: '+36 HUNGARY', fullname: 'HUNGARY' },
	{ flag: 'ID', code: '+62', value: '+62 INDONESIA', fullname: 'INDONESIA' },
	{ flag: 'IE', code: '+353', value: '+353 IRELAND', fullname: 'IRELAND' },
	{ flag: 'IL', code: '+972', value: '+972 ISRAEL', fullname: 'ISRAEL' },
	{ flag: '', code: '+44', value: '+44 ISLE OF MAN', fullname: 'ISLE OF MAN' },
	{ flag: 'IN', code: '+91', value: '+91 INDIA', fullname: 'INDIA' },
	{ flag: 'IQ', code: '+964', value: '+964 IRAQ', fullname: 'IRAQ' },
	{
		flag: 'IR',
		code: '+98',
		value: '+98 IRAN, ISLAMIC REPUBLIC OF',
		fullname: 'IRAN, ISLAMIC REPUBLIC OF',
	},
	{ flag: 'IS', code: '+354', value: '+354 ICELAND', fullname: 'ICELAND' },
	{ flag: 'IT', code: '+39', value: '+39 ITALY', fullname: 'ITALY' },
	{ flag: 'JM', code: '+1876', value: '+1876 JAMAICA', fullname: 'JAMAICA' },
	{ flag: 'JO', code: '+962', value: '+962 JORDAN', fullname: 'JORDAN' },
	{ flag: 'JP', code: '+81', value: '+81 JAPAN', fullname: 'JAPAN' },
	{ flag: 'KE', code: '+254', value: '+254 KENYA', fullname: 'KENYA' },
	{
		flag: 'KG',
		code: '+996',
		value: '+996 KYRGYZSTAN',
		fullname: 'KYRGYZSTAN',
	},
	{ flag: 'KH', code: '+855', value: '+855 CAMBODIA', fullname: 'CAMBODIA' },
	{ flag: 'KI', code: '+686', value: '+686 KIRIBATI', fullname: 'KIRIBATI' },
	{ flag: 'KM', code: '+269', value: '+269 COMOROS', fullname: 'COMOROS' },
	{
		flag: 'KN',
		code: '+1869',
		value: '+1869 SAINT KITTS AND NEVIS',
		fullname: 'SAINT KITTS AND NEVIS',
	},
	{
		flag: 'KP',
		code: '+850',
		value: '+850 KOREA DEMOCRATIC PEOPLES REPUBLIC OF',
		fullname: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF',
	},
	{
		flag: 'KR',
		code: '+82',
		value: '+82 KOREA REPUBLIC OF',
		fullname: 'KOREA REPUBLIC OF',
	},
	{ flag: 'KW', code: '+965', value: '+965 KUWAIT', fullname: 'KUWAIT' },
	{
		flag: 'KY',
		code: '+1345',
		value: '+1345 CAYMAN ISLANDS',
		fullname: 'CAYMAN ISLANDS',
	},
	{ flag: 'KZ', code: '+7', value: '+7 KAZAKSTAN', fullname: 'KAZAKSTAN' },
	{
		flag: 'LA',
		code: '+856',
		value: '+856 LAO PEOPLES DEMOCRATIC REPUBLIC',
		fullname: 'LAO PEOPLES DEMOCRATIC REPUBLIC',
	},
	{ flag: 'LB', code: '+961', value: '+961 LEBANON', fullname: 'LEBANON' },
	{
		flag: 'LC',
		code: '+1758',
		value: '+1758 SAINT LUCIA',
		fullname: 'SAINT LUCIA',
	},
	{
		flag: 'LI',
		code: '+423',
		value: '+423 LIECHTENSTEIN',
		fullname: 'LIECHTENSTEIN',
	},
	{ flag: 'LK', code: '+94', value: '+94 SRI LANKA', fullname: 'SRI LANKA' },
	{ flag: 'LR', code: '+231', value: '+231 LIBERIA', fullname: 'LIBERIA' },
	{ flag: 'LS', code: '+266', value: '+266 LESOTHO', fullname: 'LESOTHO' },
	{ flag: 'LT', code: '+370', value: '+370 LITHUANIA', fullname: 'LITHUANIA' },
	{
		flag: 'LU',
		code: '+352',
		value: '+352 LUXEMBOURG',
		fullname: 'LUXEMBOURG',
	},
	{ flag: 'LV', code: '+371', value: '+371 LATVIA', fullname: 'LATVIA' },
	{
		flag: 'LY',
		code: '+218',
		value: '+218 LIBYAN ARAB JAMAHIRIYA',
		fullname: 'LIBYAN ARAB JAMAHIRIYA',
	},
	{ flag: 'MA', code: '+212', value: '+212 MOROCCO', fullname: 'MOROCCO' },
	{ flag: 'MC', code: '+377', value: '+377 MONACO', fullname: 'MONACO' },
	{
		flag: 'MD',
		code: '+373',
		value: '+373 MOLDOVA, REPUBLIC OF',
		fullname: 'MOLDOVA, REPUBLIC OF',
	},
	{
		flag: 'ME',
		code: '+382',
		value: '+382 MONTENEGRO',
		fullname: 'MONTENEGRO',
	},
	{
		flag: '',
		code: '+1599',
		value: '+1599 SAINT MARTIN',
		fullname: 'SAINT MARTIN',
	},
	{
		flag: 'MG',
		code: '+261',
		value: '+261 MADAGASCAR',
		fullname: 'MADAGASCAR',
	},
	{
		flag: 'MH',
		code: '+692',
		value: '+692 MARSHALL ISLANDS',
		fullname: 'MARSHALL ISLANDS',
	},
	{
		flag: 'MK',
		code: '+389',
		value: '+389 MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
		fullname: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
	},
	{ flag: 'ML', code: '+223', value: '+223 MALI', fullname: 'MALI' },
	{ flag: 'MM', code: '+95', value: '+95 MYANMAR', fullname: 'MYANMAR' },
	{ flag: 'MN', code: '+976', value: '+976 MONGOLIA', fullname: 'MONGOLIA' },
	{ flag: 'MO', code: '+853', value: '+853 MACAU', fullname: 'MACAU' },
	{
		flag: 'MP',
		code: '+1670',
		value: '+1670 NORTHERN MARIANA ISLANDS',
		fullname: 'NORTHERN MARIANA ISLANDS',
	},
	{
		flag: 'MR',
		code: '+222',
		value: '+222 MAURITANIA',
		fullname: 'MAURITANIA',
	},
	{
		flag: 'MS',
		code: '+1664',
		value: '+1664 MONTSERRAT',
		fullname: 'MONTSERRAT',
	},
	{ flag: 'MT', code: '+356', value: '+356 MALTA', fullname: 'MALTA' },
	{ flag: 'MU', code: '+230', value: '+230 MAURITIUS', fullname: 'MAURITIUS' },
	{ flag: 'MV', code: '+960', value: '+960 MALDIVES', fullname: 'MALDIVES' },
	{ flag: 'MW', code: '+265', value: '+265 MALAWI', fullname: 'MALAWI' },
	{ flag: 'MX', code: '+52', value: '+52 MEXICO', fullname: 'MEXICO' },
	{ flag: 'MY', code: '+60', value: '+60 MALAYSIA', fullname: 'MALAYSIA' },
	{
		flag: 'MZ',
		code: '+258',
		value: '+258 MOZAMBIQUE',
		fullname: 'MOZAMBIQUE',
	},
	{ flag: 'NA', code: '+264', value: '+264 NAMIBIA', fullname: 'NAMIBIA' },
	{
		flag: 'NC',
		code: '+687',
		value: '+687 NEW CALEDONIA',
		fullname: 'NEW CALEDONIA',
	},
	{ flag: 'NE', code: '+227', value: '+227 NIGER', fullname: 'NIGER' },
	{ flag: 'NG', code: '+234', value: '+234 NIGERIA', fullname: 'NIGERIA' },
	{ flag: 'NI', code: '+505', value: '+505 NICARAGUA', fullname: 'NICARAGUA' },
	{
		flag: 'NL',
		code: '+31',
		value: '+31 NETHERLANDS',
		fullname: 'NETHERLANDS',
	},
	{ flag: 'NO', code: '+47', value: '+47 NORWAY', fullname: 'NORWAY' },
	{ flag: 'NP', code: '+977', value: '+977 NEPAL', fullname: 'NEPAL' },
	{ flag: 'NR', code: '+674', value: '+674 NAURU', fullname: 'NAURU' },
	{ flag: 'NU', code: '+683', value: '+683 NIUE', fullname: 'NIUE' },
	{
		flag: 'NZ',
		code: '+64',
		value: '+64 NEW ZEALAND',
		fullname: 'NEW ZEALAND',
	},
	{ flag: 'OM', code: '+968', value: '+968 OMAN', fullname: 'OMAN' },
	{ flag: 'PA', code: '+507', value: '+507 PANAMA', fullname: 'PANAMA' },
	{ flag: 'PE', code: '+51', value: '+51 PERU', fullname: 'PERU' },
	{
		flag: 'PF',
		code: '+689',
		value: '+689 FRENCH POLYNESIA',
		fullname: 'FRENCH POLYNESIA',
	},
	{
		flag: 'PG',
		code: '+675',
		value: '+675 PAPUA NEW GUINEA',
		fullname: 'PAPUA NEW GUINEA',
	},
	{
		flag: 'PH',
		code: '+63',
		value: '+63 PHILIPPINES',
		fullname: 'PHILIPPINES',
	},
	{ flag: 'PK', code: '+92', value: '+92 PAKISTAN', fullname: 'PAKISTAN' },
	{ flag: 'PL', code: '+48', value: '+48 POLAND', fullname: 'POLAND' },
	{
		flag: 'PM',
		code: '+508',
		value: '+508 SAINT PIERRE AND MIQUELON',
		fullname: 'SAINT PIERRE AND MIQUELON',
	},
	{ flag: 'PN', code: '+870', value: '+870 PITCAIRN', fullname: 'PITCAIRN' },
	{ flag: 'PR', code: '+1', value: '+1 PUERTO RICO', fullname: 'PUERTO RICO' },
	{ flag: 'PT', code: '+351', value: '+351 PORTUGAL', fullname: 'PORTUGAL' },
	{ flag: 'PW', code: '+680', value: '+680 PALAU', fullname: 'PALAU' },
	{ flag: 'PY', code: '+595', value: '+595 PARAGUAY', fullname: 'PARAGUAY' },
	{ flag: 'QA', code: '+974', value: '+974 QATAR', fullname: 'QATAR' },
	{ flag: 'RO', code: '+40', value: '+40 ROMANIA', fullname: 'ROMANIA' },
	{ flag: 'RS', code: '+381', value: '+381 SERBIA', fullname: 'SERBIA' },
	{
		flag: 'RU',
		code: '+7',
		value: '+7 RUSSIAN FEDERATION',
		fullname: 'RUSSIAN FEDERATION',
	},
	{ flag: 'RW', code: '+250', value: '+250 RWANDA', fullname: 'RWANDA' },
	{
		flag: 'SA',
		code: '+966',
		value: '+966 SAUDI ARABIA',
		fullname: 'SAUDI ARABIA',
	},
	{
		flag: 'SB',
		code: '+677',
		value: '+677 SOLOMON ISLANDS',
		fullname: 'SOLOMON ISLANDS',
	},
	{
		flag: 'SC',
		code: '+248',
		value: '+248 SEYCHELLES',
		fullname: 'SEYCHELLES',
	},
	{ flag: 'SD', code: '+249', value: '+249 SUDAN', fullname: 'SUDAN' },
	{ flag: 'SE', code: '+46', value: '+46 SWEDEN', fullname: 'SWEDEN' },
	{ flag: 'SG', code: '+65', value: '+65 SINGAPORE', fullname: 'SINGAPORE' },
	{
		flag: 'SH',
		code: '+290',
		value: '+290 SAINT HELENA',
		fullname: 'SAINT HELENA',
	},
	{ flag: 'SI', code: '+386', value: '+386 SLOVENIA', fullname: 'SLOVENIA' },
	{ flag: 'SK', code: '+421', value: '+421 SLOVAKIA', fullname: 'SLOVAKIA' },
	{
		flag: 'SL',
		code: '+232',
		value: '+232 SIERRA LEONE',
		fullname: 'SIERRA LEONE',
	},
	{
		flag: 'SM',
		code: '+378',
		value: '+378 SAN MARINO',
		fullname: 'SAN MARINO',
	},
	{ flag: 'SN', code: '+221', value: '+221 SENEGAL', fullname: 'SENEGAL' },
	{ flag: 'SO', code: '+252', value: '+252 SOMALIA', fullname: 'SOMALIA' },
	{ flag: 'SR', code: '+597', value: '+597 SURINAME', fullname: 'SURINAME' },
	{
		flag: 'ST',
		code: '+239',
		value: '+239 SAO TOME AND PRINCIPE',
		fullname: 'SAO TOME AND PRINCIPE',
	},
	{
		flag: 'SV',
		code: '+503',
		value: '+503 EL SALVADOR',
		fullname: 'EL SALVADOR',
	},
	{
		flag: 'SY',
		code: '+963',
		value: '+963 SYRIAN ARAB REPUBLIC',
		fullname: 'SYRIAN ARAB REPUBLIC',
	},
	{ flag: 'SZ', code: '+268', value: '+268 SWAZILAND', fullname: 'SWAZILAND' },
	{
		flag: 'TC',
		code: '+1649',
		value: '+1649 TURKS AND CAICOS ISLANDS',
		fullname: 'TURKS AND CAICOS ISLANDS',
	},
	{ flag: 'TD', code: '+235', value: '+235 CHAD', fullname: 'CHAD' },
	{ flag: 'TG', code: '+228', value: '+228 TOGO', fullname: 'TOGO' },
	{ flag: 'TH', code: '+66', value: '+66 THAILAND', fullname: 'THAILAND' },
	{
		flag: 'TJ',
		code: '+992',
		value: '+992 TAJIKISTAN',
		fullname: 'TAJIKISTAN',
	},
	{ flag: 'TK', code: '+690', value: '+690 TOKELAU', fullname: 'TOKELAU' },
	{
		flag: 'TL',
		code: '+670',
		value: '+670 TIMOR-LESTE',
		fullname: 'TIMOR-LESTE',
	},
	{
		flag: 'TM',
		code: '+993',
		value: '+993 TURKMENISTAN',
		fullname: 'TURKMENISTAN',
	},
	{ flag: 'TN', code: '+216', value: '+216 TUNISIA', fullname: 'TUNISIA' },
	{ flag: 'TO', code: '+676', value: '+676 TONGA', fullname: 'TONGA' },
	{ flag: 'TR', code: '+90', value: '+90 TURKEY', fullname: 'TURKEY' },
	{
		flag: 'TT',
		code: '+1868',
		value: '+1868 TRINIDAD AND TOBAGO',
		fullname: 'TRINIDAD AND TOBAGO',
	},
	{ flag: 'TV', code: '+688', value: '+688 TUVALU', fullname: 'TUVALU' },
	{
		flag: 'TW',
		code: '+886',
		value: '+886 TAIWAN, PROVINCE OF CHINA',
		fullname: 'TAIWAN, PROVINCE OF CHINA',
	},
	{
		flag: 'TZ',
		code: '+255',
		value: '+255 TANZANIA, UNITED REPUBLIC OF',
		fullname: 'TANZANIA, UNITED REPUBLIC OF',
	},
	{ flag: 'UA', code: '+380', value: '+380 UKRAINE', fullname: 'UKRAINE' },
	{ flag: 'UG', code: '+256', value: '+256 UGANDA', fullname: 'UGANDA' },
	{
		flag: 'US',
		code: '+1',
		value: '+1 UNITED STATES',
		fullname: 'UNITED STATES',
	},
	{ flag: 'UY', code: '+598', value: '+598 URUGUAY', fullname: 'URUGUAY' },
	{
		flag: 'UZ',
		code: '+998',
		value: '+998 UZBEKISTAN',
		fullname: 'UZBEKISTAN',
	},
	{
		flag: 'VA',
		code: '+39',
		value: '+39 VATICAN CITY',
		fullname: 'VATICAN CITY',
	},
	{
		flag: 'VC',
		code: '+1784',
		value: '+1784 SAINT VINCENT AND THE GRENADINES',
		fullname: 'SAINT VINCENT AND THE GRENADINES',
	},
	{ flag: 'VE', code: '+58', value: '+58 VENEZUELA', fullname: 'VENEZUELA' },
	{
		flag: 'VG',
		code: '+1284',
		value: '+1284 VIRGIN ISLANDS, BRITISH',
		fullname: 'VIRGIN ISLANDS, BRITISH',
	},
	{
		flag: 'VI',
		code: '+1340.',
		value: '+1340 VIRGIN ISLANDS, U.S.',
		fullname: 'VIRGIN ISLANDS, U.S.',
	},
	{ flag: 'VN', code: '+84', value: '+84 VIET NAM', fullname: 'VIET NAM' },
	{ flag: 'VU', code: '+678', value: '+678 VANUATU', fullname: 'VANUATU' },
	{
		flag: 'WF',
		code: '+681',
		value: '+681 WALLIS AND FUTUNA',
		fullname: 'WALLIS AND FUTUNA',
	},
	{ flag: 'WS', code: '+685', value: '+685 SAMOA', fullname: 'SAMOA' },
	{ flag: '', code: '+381', value: '+381 KOSOVO', fullname: 'KOSOVO' },
	{ flag: 'YE', code: '+967', value: '+967 YEMEN', fullname: 'YEMEN' },
	{ flag: 'YT', code: '+262', value: '+262 MAYOTTE', fullname: 'MAYOTTE' },
	{
		flag: 'ZA',
		code: '+27',
		value: '+27 SOUTH AFRICA',
		fullname: 'SOUTH AFRICA',
	},
	{ flag: 'ZM', code: '+260', value: '+260 ZAMBIA', fullname: 'ZAMBIA' },
	{ flag: 'ZW', code: '+26', value: '+26 ZIMBABWE', fullname: 'ZIMBABWE' },
];
