import { errorLog } from '@monorepo/workers/logger';

import { Order, upsellAPI } from '../..';

/**
 * Cancels an order with the specified order ID.
 * @param orderID - The ID of the order to be cancelled.
 * @returns A promise that resolves to the cancelled order data.
 * @throws An error if the order cancellation fails.
 */
export const cancelOrder = async (orderID: string, type = 'default') => {
	return upsellAPI()
		.delete<{
			data: Order;
		}>(`/orders/${type}/${orderID}`)
		.then((res) => res.data.data)
		.catch((err) => {
			errorLog({ error: err.message });
			throw new Error('Failed to cancel order');
		});
};
