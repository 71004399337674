import {
	CartItemProduct,
	CartItemProductSingle,
	CartItemProductSplit,
	DealItem,
	Price,
	calculateDiscount,
	calculateExtras,
	calculateVariation,
	combineSubPrice,
} from '../../../..';

export const calculateProduct = (cartItemProduct: CartItemProduct | DealItem): Price => {
	if (cartItemProduct.outputSplit === null) {
		const cartItemProductSingle = cartItemProduct as CartItemProductSingle;

		const base = calculateVariation(cartItemProductSingle);
		const extra = calculateExtras(cartItemProductSingle);
		const price = base + extra;

		const quantity = cartItemProductSingle.quantity;
		const discount = cartItemProductSingle.output._data.discount;

		return {
			base: calculateDiscount(base, quantity, discount),
			extra: calculateDiscount(extra, quantity, discount),
			price: calculateDiscount(price, quantity, discount),
		};
	} else {
		const cartItemProductSplit = cartItemProduct as CartItemProductSplit;
		const left = calculateProduct({ ...cartItemProductSplit, outputSplit: null });
		const right = calculateProduct({
			...cartItemProductSplit,
			output: cartItemProductSplit.outputSplit,
			outputSplit: null,
		});

		return {
			base: combineSubPrice(left.base, right.base),
			extra: combineSubPrice(left.extra, right.extra),
			price: combineSubPrice(left.price, right.price),
		};
	}
};
