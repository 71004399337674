import { DealItemOutput, Output } from '../../..';
import { DealExtra } from './convertDeal';
import { Extra } from './convertProductSingle';

export const formatExtras = (
	output: Output | DealItemOutput,
	freeExtras?: string[],
): Extra[] | DealExtra[] => {
	const extrasData = output._data.extras;

	return output.extras.map((outputExtra) => {
		const extraData = extrasData.find((dataExtra) => dataExtra._id === outputExtra._id);

		if (!extraData) {
			throw new Error('Invalid extra data not found');
		}

		return {
			extras_id: outputExtra._id,
			items: outputExtra.items.map((outputExtraItem) => {
				const extraItemData = extraData.items.find(
					(extraDataItem) => extraDataItem._id === outputExtraItem._id,
				);

				if (!extraItemData) {
					throw new Error('Invalid extra item data not found');
				}

				const quantity = outputExtraItem.quantity;
				const extras_item_id = outputExtraItem._id;
				const free_extras =
					freeExtras?.filter((freeExtra) => freeExtra === extras_item_id).length ||
					undefined;

				return {
					quantity,
					extras_item_id,
					free_extras,
				};
			}),
		};
	});
};
