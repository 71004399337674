import { User, tokenStorage, upsellAPI } from '../..';

/**
 * Function to fetch deals from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {User} Array of Category data
 */
export const getUser = (signal?: AbortSignal) => {
	const { accessToken } = tokenStorage.get();
	if (!accessToken) {
		return undefined;
	}
	return upsellAPI()
		.get<{
			data: User;
		}>('/customers', {
			signal,
			headers: {
				Authorization: 'Bearer ' + accessToken,
			},
		})
		.then((res) => res.data.data);
};
