import { addMinutes, differenceInMilliseconds, isAfter, parseISO } from 'date-fns';

export const calculateEstimationProgress = (
	createdAt: string,
	durationInMinutes: number,
	currentTime: Date,
) => {
	const startDate = parseISO(createdAt);
	const endDate = addMinutes(startDate, durationInMinutes);

	const totalDuration = differenceInMilliseconds(endDate, startDate);
	const elapsedDuration = differenceInMilliseconds(currentTime, startDate);

	const percentageElapsed = isAfter(currentTime, endDate)
		? 100
		: (elapsedDuration / totalDuration) * 100;

	return {
		percentageElapsed,
		endDate,
	};
};
