const textReducer = (array: string[], separator = ', ', lastSeparator = ' and ') =>
	array.reduce(
		(acc, name, idx, arr) =>
			acc + (idx === 0 ? '' : idx === arr.length - 1 ? lastSeparator : separator) + name,
		'',
	);

export const joinText = {
	withAnd: (texts: string[]) => textReducer(texts, ', ', ' and '),
	withOr: (texts: string[]) => textReducer(texts, ', ', ' or '),
};
