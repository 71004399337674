import { trace } from '@opentelemetry/api';
import { MaskActions, maskAttribute } from 'nested-mask-attributes';

enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  ERROR = 'error',
}

const maskedFields = [
  'name',
  'phone',
  'phoneNumber',
  'email',
  'emailAddress',
  'ssn',
  'address',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSafeLogMessage(message: any): any {
  return maskAttribute(structuredClone(message), maskedFields, {
    action: MaskActions.MASK,
  });
}

export function debugLog(message: unknown): void {
  const span = trace.getActiveSpan();
  console.log(
    JSON.stringify({
      message: { ...getSafeLogMessage(message) },
      level: LogLevel.DEBUG,
      traceId: span?.spanContext().traceId,
    }),
  );
}

export function infoLog(message: unknown): void {
  const span = trace.getActiveSpan();
  console.log(
    JSON.stringify({
      message: { ...getSafeLogMessage(message) },
      level: LogLevel.INFO,
      traceId: span?.spanContext().traceId,
    }),
  );
}

export function errorLog(message: unknown): void {
  const span = trace.getActiveSpan();
  span?.setAttribute('error', true);
  console.log(
    JSON.stringify({
      message: { ...getSafeLogMessage(message) },
      level: LogLevel.ERROR,
      traceId: span?.spanContext().traceId,
    }),
  );
}
