import { GiftcardItemType, tokenStorage, upsellAPI } from '../..';

/**
 * Function to fetch all available giftcards for user
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {GiftcardItemType[]} Array of filtered giftcard data
 */
export const getGiftcards = (signal?: AbortSignal) => {
	const { accessToken } = tokenStorage.get();
	if (!accessToken) {
		return undefined;
	}

	const endpoint = `/giftcards`;

	return upsellAPI()
		.get<{
			data: GiftcardItemType[];
		}>(endpoint, {
			signal,
			headers: {
				user: accessToken,
			},
		})
		.then((res) => {
			const giftcards = res.data.data;

			const filtered = giftcards.filter((giftcard) => validateGiftcard(giftcard));

			return filtered.sort(
				(a, b) =>
					b.individual_giftcard.initial_value -
					b.individual_giftcard.value_used -
					(a.individual_giftcard.initial_value - a.individual_giftcard.value_used),
			);
		});
};

export const validateGiftcard = (giftcard: GiftcardItemType) => {
	const INVALID_STATUS = ['full_used', 'disabled'];

	if (INVALID_STATUS.includes(giftcard.individual_giftcard.status)) {
		return false;
	}

	if (giftcard.individual_giftcard.value_used === giftcard.individual_giftcard.initial_value) {
		return false;
	}

	return true;
};
