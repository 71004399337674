/**
 * Validates input onBlur event for form fields.
 * @param e - Event object representing the onBlur event for the input field.
 * @returns Error message if validation fails, otherwise null.
 *
 * NOTE: Currently we using React.js event types, but we can also use the native HTML event types.
 */
export const validateOnBlur = (
	e: React.FocusEvent<HTMLInputElement>,
): { isError: true; errorMessage: string } | { isError: false; errorMessage: '' } => {
	const target = e.target as HTMLInputElement;
	const name = target.name;
	const value = target.value;

	// Check if the field is empty
	if (!value) {
		return { isError: true, errorMessage: 'This field is required' };
	}

	if (typeof window.BAPIjs !== 'undefined') {
		// Validate card number
		if (name === 'card_number') {
			const cardNumber = value.replace(/\s/g, '');
			const isValid = window.BAPIjs.isValidCardNumber(cardNumber);
			if (!isValid) {
				return { isError: true, errorMessage: 'Invalid card number' };
			}
		}

		// Validate card expiry
		if (name === 'card_expiry') {
			const expiry = value.split('/');
			const isValid = window.BAPIjs.isValidExpDate(expiry[0], expiry[1]);
			if (!isValid) {
				return { isError: true, errorMessage: 'Invalid expiry' };
			}
		}

		// Validate card CVC
		if (name === 'card_cvc') {
			const isValid = window.BAPIjs.isValidCVC(value);
			if (!isValid) {
				return { isError: true, errorMessage: 'Invalid cvc' };
			}
		}
	}

	// If all validations pass, return null
	return { isError: false, errorMessage: '' };
};
