import { UpsellConfig } from '../global';
import { preload } from './';

/**
 * Initiatize Upsell Kit and preloading necessary data
 * @param {UpsellConfig} upsellConfig defines a store ID and platform ('web' or 'app')
 */
export const init = (config: UpsellConfig) => {
	if (typeof window !== 'undefined') {
		window.upsellConfig = config;
		preload();
	}
};
