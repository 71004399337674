import { OrderProductSingle, Output, fetchers } from '../../..';

export const constructOutput = async (orderItem: OrderProductSingle): Promise<Output> => {
	const productData = await fetchers.getProductByID(orderItem.product_id);

	return {
		variables:
			'variation' in orderItem
				? orderItem.variation.variants.map((variant) => ({
						variable: variant.variant,
						attribute: variant.attribute,
					}))
				: [],
		extras:
			'extras' in orderItem
				? orderItem.extras.map((extra) => ({
						_id: extra.extras_id,
						items: extra.items.map((item) => ({
							_id: item.extras_item_id,
							quantity: item.quantity,
							timestamp: 0, // [TODO]: refactor jadiin bukan timestamp
						})),
					}))
				: [],
		_data: productData, // [TODO]: need to revalidate the data snapshot
	};
};
