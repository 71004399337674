/**
 * Retrieves the primary item from an array of objects based on a 'primary' property.
 *
 * @param {T[]} arr - The array of objects.
 * @returns {T | undefined} The primary item if found, otherwise undefined.
 */
export const getPrimary = <
	T extends {
		[key: string]: unknown;
		primary: boolean;
	},
>(
	arr: T[],
) => {
	if (arr.length <= 0) {
		return undefined;
	}
	const primaryItem = arr.find((item) => item.primary);
	if (primaryItem) {
		return primaryItem;
	}
	return arr[0];
};
