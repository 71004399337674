import { Output, Product, ProductExtended } from '..';

/**
 * Converts a variation to variables format.
 *
 * @param {Product['variations'][0]} variation - The variation data.
 * @returns {Output['variables']} The variables data.
 */
const toVariables = (variation: Product['variations'][0]): Output['variables'] => {
	if (!variation) {
		return [];
	}
	const result = variation.variants.map((variant) => ({
		variable: variant.attribute.variable,
		attribute: variant.attribute._id,
	}));

	return result;
};

/**
 * Converts variables to a variation format.
 *
 * @param {Output['variables']} variables - The variables data.
 * @param {Product['variations']} variations - The variations data.
 * @returns {Product['variations'][0] | null} The variation data.
 */
const toVariation = (
	variables: Output['variables'],
	variations: Product['variations'],
): Product['variations'][0] | null => {
	const getVariation = () => {
		return variations.find((variation) => {
			const variants = variation.variants.map((variant) => ({
				variable: variant.variable._id || variant.variable,
				attributes: variant.attribute._id || variant.attribute,
			}));

			const matches = variants.filter(
				(variant) =>
					variables.find(
						(variable) =>
							variable.variable === variant.variable &&
							variable.attribute === variant.attributes,
					) !== undefined,
			);

			return matches.length === variants.length;
		});
	};

	const variation = getVariation();
	if (variation) {
		return variation;
	} else {
		return null;
	}
};

/**
 * Converts variables to available variations format.
 *
 * @param {Output['variables']} variables - The variables data.
 * @param {ProductExtended['available_variations']} availableVariations - The available variations data.
 * @returns {ProductExtended['available_variations']} The available variations data.
 */
const toAvailableVariations = (
	variables: Output['variables'],
	availableVariations: ProductExtended['available_variations'],
) => {
	return availableVariations.find((available_variation) =>
		available_variation.variants.every((variant) =>
			variables.find(
				(variable) =>
					variant.variant === variable.variable &&
					variant.attribute === variable.attribute,
			),
		),
	);
};

export const convertVariations = {
	toVariables,
	toVariation,
	toAvailableVariations,
};
