import { mutate } from 'swr';

import { tokenStorage } from '../..';

/**
 * Start a guest mode session
 *
 */
export const start = () => {
	const { guestToken, accessToken } = tokenStorage.get();
	if (!guestToken && !accessToken) {
		tokenStorage.save({
			guestToken: {
				name: '',
				address: '',
				email: {
					address: '',
					verified: false,
				},
				phone: '',
				orders: [],
			},
		});
		mutate('user');
	}
};
