import {
	CartItem,
	CartItemDeal,
	CartItemProductSingle,
	CartItemProductSplit,
	calculateCartItem,
	toCurrency,
} from '..';

export const getDiscountText = (item: CartItem): string => {
	if (item.outputDeal) {
		const { discount } = (item as CartItemDeal).outputDeal._data;

		if (!discount) {
			return '';
		}

		const { amount, type } = discount;

		if (type === 'percent') {
			return `${amount}% OFF`;
		} else {
			return `${toCurrency(amount)} OFF`;
		}
	} else if (item.output) {
		if (item.outputSplit) {
			const price = calculateCartItem(item as CartItemProductSplit);
			return `${toCurrency(price.price.previous - price.price.final)} OFF`;
		} else {
			const { discount } = (item as CartItemProductSingle).output._data;

			if (!discount) {
				return '';
			}

			const { amount, type } = discount;

			if (type === 'percent') {
				return `${amount}% OFF`;
			} else {
				return `${toCurrency(amount)} OFF`;
			}
		}
	} else {
		return '';
	}
};
