import { Output, Product } from '../../../..';

export const calculateExtraItem = (
	item: Output['extras'][0]['items'][0],
	extraData: Product['extras'][0],
	outputVariables: Output['variables'],
	freeExtraQty: number,
) => {
	const itemData = extraData.items.find((itemData) => itemData._id === item._id);
	if (!itemData) {
		throw new Error('Invalid item, no item data found');
	}

	const freeQuantity = freeExtraQty + (itemData.include ? 1 : 0);
	const quantity = item.quantity - freeQuantity;

	if (!itemData.variant_pricing || itemData.variants.length === 0) {
		return itemData.price * quantity;
	} else {
		const variant = itemData.variants.find((variant) => {
			const variable = variant.variant_item.variable;
			const attribute = variant.variant_item._id;
			const variator =
				outputVariables.find((outputVariable) => outputVariable.variable === variable)
					?.attribute || '';
			return attribute === variator;
		});

		if (!variant) {
			return 0;
		}

		return variant.price * quantity;
	}
};
