import {
	CartItemDeal,
	DealItemSingle,
	DealItemSplit,
	Override,
	determineFreeExtras,
} from '../../..';
import { OrderItemProductSingle, convertProductSingle } from './convertProductSingle';
import { formatExtras } from './formatExtras';

type OrderItemDeal = {
	deals_id: string;
	quantity: number;
	item_note: '';
	menus?: Menu[];
};

type Menu = {
	menu_id: string;
	menu_items: [MenuItem];
};

type MenuItem = MenuItemSingle | MenuItemSplit;

type MenuItemSingle = Override<
	Omit<OrderItemProductSingle, 'item_note'> & {
		menu_item_id: string;
	},
	{
		extras: DealExtra[];
	}
>;

export type DealExtra = Override<
	OrderItemProductSingle['extras'][0],
	{
		items: (OrderItemProductSingle['extras'][0]['items'][0] & {
			free_extras: number;
		})[];
	}
>;

type MenuItemSplit = {
	menu_item_id: string;
	quantity: number;
	type: 'split_product';
	split_items: [MenuItemSingle, MenuItemSingle];
};

export const convertDeal = (cartItemDeal: CartItemDeal): OrderItemDeal => {
	const { outputDeal, quantity } = cartItemDeal;

	const menus: Menu[] = outputDeal.items.map((dealItem) => {
		if (dealItem.outputSplit) {
			return convertDealItemSplit(dealItem as DealItemSplit);
		} else {
			return convertDealItemSingle(dealItem as DealItemSingle);
		}
	});

	return {
		deals_id: outputDeal._data._id,
		quantity,
		item_note: '',
		menus,
	};
};

export const convertDealItemSingle = (dealItemSingle: DealItemSingle): Menu => {
	const orderItemProductSingle = convertProductSingle({
		output: dealItemSingle.output,
		outputSplit: dealItemSingle.outputSplit,
		outputDeal: null,
		quantity: dealItemSingle.quantity,
	});

	const menuItem: MenuItemSingle = {
		...orderItemProductSingle,
		menu_item_id: dealItemSingle.output._data.menu_item_id,
		extras: formatExtras(
			dealItemSingle.output,
			determineFreeExtras(dealItemSingle),
		) as DealExtra[],
	};

	return {
		menu_id: dealItemSingle.menuID,
		menu_items: [menuItem],
	};
};

const convertDealItemSplit = (dealItemSplit: DealItemSplit): Menu => {
	const left: DealItemSingle = {
		menuID: dealItemSplit.menuID,
		output: dealItemSplit.output,
		outputSplit: null,
		quantity: 1,
	};

	const right: DealItemSingle = {
		menuID: dealItemSplit.menuID,
		output: dealItemSplit.outputSplit,
		outputSplit: null,
		quantity: 1,
	};

	const split_items: [MenuItemSingle, MenuItemSingle] = [
		convertDealItemSingle(left).menu_items[0] as MenuItemSingle,
		convertDealItemSingle(right).menu_items[0] as MenuItemSingle,
	];

	const menuItemSplit: MenuItemSplit = {
		menu_item_id: dealItemSplit.output._data.menu_item_id,
		quantity: dealItemSplit.quantity,
		type: 'split_product',
		split_items,
	};

	const menu_items: [MenuItemSplit] = [menuItemSplit];

	return {
		menu_id: dealItemSplit.menuID,
		menu_items,
	};
};
