import { mutate } from 'swr';

/**
 * Redeems a gift card with the specified gift card code.
 * @param couponCode - The code of the gift card to be redeemed.
 * @returns A promise that resolves to the redeemed gift card data.
 * @throws An error if the gift card redemption fails.
 */
export const remove = async () => {
	window.couponCode = '';
	mutate('coupon', undefined);
};
