import { mutate } from 'swr';

import { tokenStorage } from './storage/tokenStorage';

export const saveGuestOrder = (orderID: string): void => {
	const { guestToken, accessToken } = tokenStorage.get();

	if (!accessToken && guestToken) {
		tokenStorage.save({
			guestToken: { ...guestToken, orders: [...guestToken.orders, orderID] },
		});
		mutate('guest');
	}
};
