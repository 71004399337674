import { Order, constructParams, populateItemsAsCart, tokenStorage, upsellAPI } from '../..';
import { getOrderByID } from './getOrderByID';

/**
 * Function to fetch orders from Upsell API
 * @param {Record<string, string | string[]>} params? - Optional query parameters
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Promise<Order[]>} Array of Order data
 */
export const getOrders = async (
	params?: Record<string, string | string[]>,
	signal?: AbortSignal,
): Promise<Order[] | undefined> => {
	const { accessToken, guestToken } = tokenStorage.get();
	if (!accessToken) {
		if (guestToken) {
			const promises = guestToken.orders.map((orderID) => {
				return getOrderByID(orderID);
			});

			return (await Promise.all(promises)).filter((order) => {
				const wantedStatuses = params?.order_status;
				if (wantedStatuses) {
					return wantedStatuses.includes(order.order_status);
				} else {
					return true;
				}
			});
		} else {
			return undefined;
		}
	}

	const endpoint = params ? constructParams(`/orders`, params) : '/orders';
	if (!endpoint) {
		return undefined;
	}

	return upsellAPI()
		.get<{ data: Order[] }>(endpoint, {
			signal,
			headers: {
				user: accessToken,
			},
		})
		.then((res) => {
			return Promise.all(
				res.data.data.map(async (order) => await populateItemsAsCart(order)),
			);
		});
};
