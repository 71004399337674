import useSWR from 'swr';

import { fetchers } from '../..';

/**
 * SWR hook to fetch active orders
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useOrders = () =>
	useSWR('orders', () => {
		fetchers.getOrders();
	});
