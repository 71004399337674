import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface UpsellProps {
	baseUrl: string;
	options: {
		store_id: string;
	};
}

export class Upsell {
	private static instance: Upsell | null = null;
	public client: AxiosInstance;
	public baseUrl: string;
	public Authorization: string;

	constructor({ options, baseUrl }: UpsellProps) {
		this.client = axios.create({
			baseURL: baseUrl,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			withCredentials: true,
		});
		this.baseUrl = baseUrl;
		this.Authorization = 'MobileApp ' + options.store_id; // TODO why is this mobile app
	}

	public static getInstance(props: UpsellProps): Upsell {
		if (this.instance === null) {
			this.instance = new Upsell(props);
		}
		return this.instance;
	}

	async get<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.client
			.get<T>(path, {
				...config,
				headers: {
					authorization: this.Authorization,
					...config?.headers,
				},
				withCredentials: true,
			})
			.then((response) => {
				return response;
			});
	}

	async post<T>(
		path: string,
		body?: unknown,
		config?: AxiosRequestConfig,
	): Promise<AxiosResponse<T>> {
		return this.client.post<T>(path, body, {
			...config,
			headers: {
				authorization: this.Authorization,
				...config?.headers,
			},
			withCredentials: true,
		});
	}

	async put<T>(
		path: string,
		body?: unknown,
		config?: AxiosRequestConfig,
	): Promise<AxiosResponse<T>> {
		return this.client.put<T>(path, body, {
			...config,
			headers: {
				authorization: this.Authorization,
				...config?.headers,
			},
			withCredentials: true,
		});
	}

	async delete<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.client.delete<T>(path, {
			...config,
			headers: {
				authorization: this.Authorization,
				...config?.headers,
			},
			withCredentials: true,
		});
	}
}

/**
 * Axios instance for upsell server
 * @returns returning axios instance
 */
export const upsellAPI = () => {
	return Upsell.getInstance({
		baseUrl: 'https://public-api.upsell.is/v2',
		// baseUrl: 'http://localhost:8080/v2',
		// baseUrl: 'https://0232-36-74-41-50.ngrok-free.app/v2',
		options: { store_id: typeof window !== 'undefined' ? window?.upsellConfig?.storeID : '' },
	});
};
