import { errorLog } from '@monorepo/workers/logger';

import { Coupon, upsellAPI } from '../..';

/**
 * Redeems a gift card with the specified gift card code.
 * @param couponCode - The code of the gift card to be redeemed.
 * @returns A promise that resolves to the redeemed gift card data.
 * @throws An error if the gift card redemption fails.
 */
export const get = async (couponCode: string) => {
	if (!couponCode) {
		return Promise.resolve(undefined);
	}
	return upsellAPI()
		.get<{
			data: Coupon;
		}>(`/coupon/check-coupon/${couponCode}`)
		.then((res) => {
			return res.data.data;
		})
		.catch((err) => {
			errorLog({ error: err.message });
			return undefined;
		});
};
