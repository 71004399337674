import useSWR from 'swr';

import { fetchers } from '../..';
import { OrderByIDConfig } from '../../lib/fetchers/getOrderByID';

/**
 * SWR hook to fetch success order by ID
 * @param branchID string ID of the order
 * @returns returning data, isLoading, isValidating, mutate, and error
 */

export const useOrderByID = (orderID: string, config?: OrderByIDConfig) =>
	useSWR(
		!orderID ? null : `order-by-id-${orderID}`,
		() => fetchers.getOrderByID(orderID, config),
		config?.refreshInterval
			? {
					refreshInterval: config.refreshInterval,
				}
			: undefined,
	);
