import upsell, { Order, TeyaPaymentRequest, TeyaPaymentResult, TeyaPaymentState } from '../../..';
import { getBAPIToken } from './getBapiToken';
import { getMultiToken } from './getMultiToken';

export const pay = async (payment: TeyaPaymentState, order: Order): Promise<TeyaPaymentResult> => {
	const BAPItoken = await getBAPIToken(payment);

	if (!BAPItoken) {
		throw BAPItoken;
	}

	const token = await getMultiToken({
		order_id: order._id,
		token: BAPItoken,
	});

	const teyaPaymentRequest: TeyaPaymentRequest = {
		order_id: order._id,
		token,
		thank_you_url: `${process.env.NEXT_PUBLIC_BASE_URL}/checkout/success?order=${order._id}`, // [TODO]: wrong redirection here
		verification_url: `${process.env.NEXT_PUBLIC_BASE_URL}/checkout/verification`, // [TODO]: wrong redirection here
	};

	const paymentResult = await upsell.payment.teya.request(teyaPaymentRequest);

	return {
		...paymentResult,
		payment_type: 'teya-rpg',
	};
};
